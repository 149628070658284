/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter , Link} from 'react-router-dom';
import {DefaultInput, DefaultButton} from '../../UI/atoms/index';
import { Container,Form, FormGroup} from 'reactstrap';
import Logo from './../../../assets/images/logo.jpg';
import { validate, checkValid } from '../../../utils/validations';
import {forgetPassword} from "../../../actions/AuthActions";

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formValues: {
        email: '',
      },
      validations: {
        emailV: '',
      },
      loading: false,
    }
  }

  submitForgetPassword = (e) => {
    e.preventDefault();
    this.forgetPasswordFn();
  };

  forgetPasswordFn = async () => {
    const { formValues } = this.state;
    await forgetPassword(formValues);
  }

  handleChange = (e) => {
    const { formValues } = this.state;
    let { validations } = this.state;
    const { name, value } = e.target;

    if (name === 'email') {
      validations = validate(validations, name, value);
    }

    formValues[[name]] = value;
    this.setState({ formValues, validations});
  };

  checkValidation = () => {
    const { validations } = this.state;
    const check = checkValid(validations);
    if (check) {
      return true;
    }
    return false;
  };

  componentWillReceiveProps(props) {

  }

  componentDidMount() {
    if (this.props.user) {
      if (this.props.user) {
        this.props.history.push('/agency/dashboard');
        // toast.success('user login successfully.');
      }
    }
  }


  render() {
    const { formValues: { email }, validations: { emailV } } = this.state;
    return (
        <div className="login-outer">
          <Container>
            <div className="login">
              <div className="login-left">
                <Link to={'/'}><img src={Logo} alt="Kids Upfront Logo" /></Link>
                <div className="login-left-bottom"></div>
              </div>

              <div className="login-right">
                <h2>Forget Password</h2>
                <Form>
                  <FormGroup>

                    <DefaultInput
                        name="email"
                        type="email"
                        onChange={this.handleChange}
                        value={email}
                        placeholder="Email"
                        validation={emailV}
                    />
                  </FormGroup>

                  <DefaultButton
                      name="Submit"
                      color="primary"
                      onClick={this.submitForgetPassword}
                      block="true"
                      disabled={this.checkValidation()}
                  />
                </Form>
              </div>
            </div>
          </Container>
        </div>
    );
  }
}


export default withRouter(ForgetPassword);
