import { INIT, SUCCESS, ERROR } from '../utils/constants';

const initialState = {
  phase: localStorage.getItem('phase') ? localStorage.getItem('phase') : INIT,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null,
  dynamic: null,
  error: null,
  email_exist: null,
  dynamic_user_business_detail: {},
  agency_account_detail: {},
  agency_classifications: [],
  businessUnit: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INITIAL_AUTH_NEW':
      localStorage.clear();
      return {
        phase: INIT,
        user: null,
        error: null,
      };

    case 'USER_LOGIN':
      let storeObject = {
        phase: SUCCESS,
        error: null,
      };

      if (localStorage.getItem('phase') == null || localStorage.getItem('user') == null) {
        //localStorage.setItem('phase', action.payload.status);
        localStorage.setItem('token', action.payload.data.token);
      }

      return storeObject;

    case 'USER_UPDATE':
      let actionData = {};
      if (action.payload && action.payload.id) {
        actionData = action.payload;
      }
      if (action.payload && action.payload.data && action.payload.data.id) {
        actionData = action.payload['data'];
      }
      state.user.data['user'] = actionData;
      let localData = JSON.parse(localStorage.getItem('user'));
      localData.user.data['user'] = actionData;
      localStorage.setItem('user', JSON.stringify(localData));

      return {
        ...state,
        phase: SUCCESS,
        user: state.user,
        error: null,
      };

    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        phase: SUCCESS,
        user: action.payload,
        error: null,
      };

    case 'USER_LOGIN_ERROR':
      return {
        ...state,
        phase: ERROR,
        error: action.error,
      };

    case 'CLEAR_ERROR': {
      return {
        ...state,
        error: null,
      };
    }

    case 'USER_ADD_ADDRESS':
      return {
        ...state,
        user: {
          ...state.user,
          message: {
            ...state.user.data,
            user: {
              ...state.user.data.user,
              roleProfile: {
                ...state.user.data.user.roleProfile,
                address: action.payload.length ? action.payload[0] : null,
              },
            },
          },
        },
      };
    case 'LOGIN_TOKEN':
      let storeLoginObject = {
        ...state,
        dynamic: action.payload,
      };
      localStorage.setItem('dynamic_token', action.payload?.access_token);

      return storeLoginObject;
    case 'UPDATE_USER_AFTER_DYNAMICS':
      let storeUpdateObject = {
        ...state,
        update_success: action.payload,
      };
      return storeUpdateObject;

    case 'STORE_DYNAMICS_USER_DETAILS':

      console.log('action.payload.name>>>', action.userData.is_volunteer ? action.payload && action.payload?.firstname + ' ' + (action.payload.middlename ? action.payload.middlename : '') + ' ' + action.payload?.lastname : action.payload && action.payload.name)
      let data = {
        "code": 200,
        "data": {
          "agency_code": action.userData.is_volunteer ? action.payload && action.payload.parentcustomerid_account && action.payload.parentcustomerid_account.cr676_agency_code : action.payload && action.payload.cr676_agency_code,
          "email": action.userData.email,
          "guid": action.userData.is_volunteer ? action.payload && action.payload.parentcustomerid_account && action.payload.parentcustomerid_account.accountid : action.userData.guid,
          "id": action.userData.id,
          "is_volunteer": action.userData.is_volunteer,
          "location": action.userData.is_volunteer ? action.payload && action.payload.parentcustomerid_account && action.payload.parentcustomerid_account.cr676_location : action.payload && action.payload.cr676_location,
          "parentcustomerid_account": action.userData.is_volunteer ? action.payload && action.payload.parentcustomerid_account && action.payload.parentcustomerid_account.accountid : "",
          "userPermission": action.userData.is_volunteer ? action.payload && action.payload.parentcustomerid_account && action.payload.parentcustomerid_account.cr676_full_access_permission : action.payload && action.payload.cr676_full_access_permission,
          "name": action.userData.is_volunteer ? action.payload && action.payload?.firstname + ' ' + (action.payload.middlename ? action.payload.middlename : '') + ' ' + action.payload?.lastname : action.payload && action.payload.name
        },
        "message": "success"
      }

      let storeDynamicObject = {
        ...state,
        dynamic_user_detail: action.payload,
        user: data
      };
      return storeDynamicObject;

    case 'SUCCESS_CHECK_EMAIL':
      return {
        ...state,
        email_exist: action.response,
      };
    case 'ERROR_CHECK_EMAIL':
      return {
        ...state,
        email_exist: action.response,
      };
    case 'SUCCESS_AGENCY_CODE':
      return {
        ...state,
        agency_code_exist: action.response,
      };
    case 'ERROR_AGENCY_CODE':
      return {
        ...state,
        agency_code_exist: action.response,
      };

    case 'GET_LOCATION_SUCCESS':
      return {
        ...state,
        location: action.response.data,
      };

    case 'GET_LOCATION_ERROR':
      return {
        ...state,
        location: action.response,
      };

    case 'SAVE_SYSTEM_USER_ID':
      console.log('action', action);
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            systemUserId: action.payload
          }

        }
      }
    case 'SAVE_SINGUP_SYSTEM_USER_ID':
      return {
        ...state,
        systemUserId: action.payload
      }
    case 'UPDATE_DYNAMIC_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            name: action.payload.data.firstname + ' ' + action.payload.data.middlename + ' ' + action.payload.data.lastname
          }

        },
        dynamic_user_detail: {
          ...state.dynamic_user_detail,
          firstname: action.payload.data.firstname,
          middlename: action.payload.data.middlename,
          lastname: action.payload.data.lastname,
          cr676_key_ticket_contact_position: action.payload.data.cr676_key_ticket_contact_position,
          cr676_key_ticket_contact_office_number: action.payload.data.cr676_key_ticket_contact_office_number,
          cr676_key_ticket_contact_cell_number: action.payload.data.cr676_key_ticket_contact_cell_number,
          emailaddress1: action.payload.data.emailaddress1
        },
        udate_dynamic: action.payload.response,
      };
    case 'UPDATE_DYNAMIC_ERROR':
      return {
        ...state,
        udate_dynamic: action.response,
      };

    case 'STORE_DYNAMICS_USER_BUSINESS_DETAILS':
      return {
        ...state,
        dynamic_user_business_detail: action.payload,
      };

    case 'STORE_AGENCY_ACCOUNT_DETAILS':
      return {
        ...state,
        agency_account_detail: action.payload,
      };

    case 'GET_AGENCY_CLASSIFICATIONS':
      return {
        ...state,
        agency_classifications: action.payload,
      };

    case 'SAVE_BUSINESS_UNIT':
      return {
        ...state,
        businessUnit: action.payload
      }

    default:
      return state;
  }
};

export default authReducer;