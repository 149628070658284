import validator from 'validator';

const regExPassword = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';

const checkEmail = (value) => {
    if (value) {
        if (!validator.isEmail(value)) {
            return 'Must be in correct Email format';
        }
    }
    return true;
};

const checkRequiredEmail = (value) => {
    if (!validator.isEmail(value)) {
        return 'Must be in correct Email format';
    }
    return true;
};

const checkPhoneNumber = (value) => {
    if (value) {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if ((value.match(phoneno))) {
            return true;
        } else {
            return 'Please enter the valid phone number';
        }
    }
    return true;
};

const checkPassword = (value) => {
    if (!validator.matches(value, regExPassword)) {
        return 'Must include 8 characters, both cases, 1 number & symbol';
    }
    return true;
};

const checkPasswordMatch = (p1, p2) => {
    if (p1 !== p2) {
        return 'Doesn\'t match password';
    }
    return true;
};


const required = (value, field) => {
    console.log('value filed', value, field)
    if (field === 'Organization incorporated')
        if (value === "" || value === "Select" || !value)
            return `${field} is Required`;
    if (!value || value === "") {
        return `${field} is Required`;
    }
    return true;
};

const isNumber = (value, field) => {
    if (value) {
        if (Number(value) > 0) {
            return true;
        } else {
            return `${field} should be numeric value`;
        }
    }
    return true;
};



const validate = (validations, name, value, match = null, confirm = null) => {
    switch (name) {

        case 'name':
            validations.nameV = required(value, 'Agency name');
            break;
        case 'address1_line1':
            validations.address1_line1V = required(value, 'Agency address');
            break;
        case 'address1_city':
            validations.address1_cityV = required(value, 'City');
            break;
        case 'address1_postalcode':
            validations.address1_postalcodeV = required(value, 'Postal code');
            break;
        case 'telephone1':
            validations.telephone1V = required(value, 'Phone number');
            validations.telephone1V = checkPhoneNumber(value);
            break;
        case 'cr676_signingauthority':
            validations.cr676_signingauthorityV = required(value, 'First name');
            break;
        case 'lastname':
            validations.lastnameV = required(value, 'Last name');
            break;
        case 'cr676_sa_phonenumber':
            validations.phone_numberV = required(value, 'Phone number');
            validations.phone_numberV = checkPhoneNumber(value);
            break;
        case 'emailaddress1':
            validations.emailV = required(value, 'Email');
            validations.emailV = checkRequiredEmail(value);
            break;
        case 'cr676_cellnumber':
            validations.cell_numberV = checkPhoneNumber(value);
            break;
        case 'evtech_password':
            if (confirm !== '') {
                if (checkPassword(value) === true) {
                    validations.passwordV = checkPasswordMatch(value, confirm);
                    if (validations.passwordV) { validations.password_confirmationV = true }
                } else { validations.passwordV = checkPassword(value) }
            } else { validations.passwordV = checkPassword(value) }
            break;
        case 'confirm_password':
            if (value.length >= match.length) {
                validations.password_confirmationV = checkPasswordMatch(value, match);
                if (validations.password_confirmationV) { validations.passwordV = true; }
            } else {
                if (checkPasswordMatch(value, match) == true) {
                    validations.password_confirmationV = checkPassword(value);
                } else {
                    validations.password_confirmationV = checkPasswordMatch(value, match);
                }
            }
            break;
        case 'password':
            if (value >= match) {
                validations.password_confirmationV = checkPasswordMatch(value, match);
                if (validations.password_confirmationV) { validations.passwordV = true; }
            } else {
                if (checkPasswordMatch(value, match) == true) {
                    validations.password_confirmationV = checkPassword(value);
                } else {
                    validations.password_confirmationV = checkPasswordMatch(value, match);
                }
            }
            break;
        case 'cr676_agency_code':
            validations.agency_codeV = required(value, 'Agency code');
            break;
        case 'bsi_agencyclassification':
            validations.agencyV = required(value, 'Organization incorporated')
            break;

        case 'old_password':
            validations.current_passwordV = required(value, 'old password');
            break;
        case 'email':
            validations.emailV = required(value, 'Email');
            validations.emailV = checkEmail(value);
            break;
        default:
            break;
    }

    return validations;
};

const validateLocation = (validations, name, value) => {
    switch (name) {
        case 'cr676_location':
            validations.locationV = required(value, 'Location');
            break;
        default:
            break;
    }
    return validations;
}

const validateSharedModel = (validations, name, value) => {
    switch (name) {
        case 'subject':
            validations.subjectV = required(value, 'Subject');
            break;
        case 'emailbody':
            validations.emailbodyV = required(value, 'Body');
            break;
        default:
            break;
    }
    return validations;
}
const validateChangeReq = (validations, name, value) => {
    switch (name) {
        case 'requestChaperones':
            validations.adultV = required(value, 'Adult');
            break;
        case 'requestChildren':
            validations.childrenV = required(value, 'Children');
            break;
        default:
            break;
    }
    return validations;
}
const validateStep2 = (validations, name, value, match = null) => {
    switch (name) {
        case 'new_alignmentwithmission':
            validations.new_alignmentwithmissionV = required(value, 'Services your organization provides');
            break;
        case 'new_agencybenefits':
            validations.new_agencybenefitsV = required(value, 'Benefits your organization');
            break;
        case 'new_ticketsfromotherorganizations':
            validations.new_ticketsfromotherorganizationsV = required(name, value);
            break;
        case 'cr676_short_tickets_tat':
            validations.short_ticketV = required(name, value);
            break;
        case 'cr676_pickup_tickets_from_hq':
            validations.pickup_ticketV = required(name, value);
            break;
        case 'new_liabilityinsurancelevel':
            validations.liabilityV = required(name, value);
            break;
        case 'cr676_children_serve_0_6':
            validations.children_serve_6V = isNumber(value, '0 to 6 years');
            break;
        case 'cr676_children_serve_7_12':
            validations.children_serve_7V = isNumber(value, '7 to 12 years');
            break;
        case 'cr676_children_serve_13_18':
            validations.children_serve_13V = isNumber(value, '13 to 18 years');
            break;
        default:
            break;
    }
    return validations;
};


const validateStep2CheckBoxes = (validations, name, value, categoryList, signUpData) => {
    let interestsCheckBoxV = "Please choose at least one interest checkbox"
    for (let category of categoryList) {
        console.log('if in for condition', category.name, signUpData[category.name])
        if (signUpData && category && category.name && signUpData[category.name]) {
            interestsCheckBoxV = true
            break;
        }
    }
    validations.interestsCheckBoxV = interestsCheckBoxV
    console.log('vali', validations)
    return validations;
};

const validateStep3 = (validations, name, value, match = null, confirm = null, index = null, AllternativeValidation, validateName = null, email = null, cr676_alternate_tickets_collection1, emailaddress1 = null) => {

    switch (name) {
        case 'cr676_key_ticket_contact_cell_number':
            validations.ticketContactCellNumberV = checkPhoneNumber(value);
            break;

        case 'cr676_key_ticket_contact_office_number':
            validations.ticketContactCellNumberV2 = checkPhoneNumber(value);
            break;


        case 'cr676_key_ticket_contact__email':
            if (checkEmail(value) === true) {
                if (cr676_alternate_tickets_collection1.findIndex(item => item.cr676_alternate_ticket_contact_email === value) !== -1) {
                    validations.ticketContactEmailV = "Already exiting"
                } else {
                    validations.ticketContactEmailV = checkEmail(value);
                }
            } else {
                validations.ticketContactEmailV = checkEmail(value);
            }
            // validations.ticketContactEmailV = checkEmail(value);

            break;
        case 'cr676_alternate_ticket_contact_phone_number':
            validations.AltcontactPhoneNumberV = checkPhoneNumber(value);
            break;
        case 'cr676_alternate_ticket_contact_cell_number':
            validations.AltTicketContactCellNumberV = checkPhoneNumber(value);
            break;
        // case 'cr676_alternate_ticket_contact_email':
        // if(checkEmail(value)) { 
        //     console.log('sscr676_alternate_tickets_collection1, cr676_alternate_tickets_collection1')
        //    if( cr676_alternate_tickets_collection1.findIndex(item => item.cr676_alternate_ticket_contact_email === value) !== -1 )
        //    {
        //     validations.AltTicketContactEmailV ="Already exiting"
        //    } else {
        //     validations.AltTicketContactEmailV = checkEmail(value);                   
        //    }
        // } else {
        // validations.AltTicketContactEmailV = checkEmail(value);
        // }
        // break;

        case 'cr676_key_ticket_contact__password':
            if (confirm !== '') {
                if (checkPassword(value) === true) {
                    validations.ticketContactpasswordV = checkPasswordMatch(value, confirm);
                    if (validations.ticketContactpasswordV) { validations.ticketContactpasswordconfirmationV = true }
                } else { validations.ticketContactpasswordV = checkPassword(value) }
            } else { validations.ticketContactpasswordV = checkPassword(value) }
            break;

        case 'cr676_key_ticket_contact__confirm_password':
            if (value.length >= match.length) {
                validations.ticketContactpasswordconfirmationV = checkPasswordMatch(value, match);
                if (validations.ticketContactpasswordconfirmationV) { validations.ticketContactpasswordV = true; }
            } else {
                if (checkPasswordMatch(value, match) == true) {
                    validations.ticketContactpasswordconfirmationV = checkPassword(value);
                } else {
                    validations.ticketContactpasswordconfirmationV = checkPasswordMatch(value, match);
                }
            }
        case 'cr676_alternate_tickets_collection1':
            if (index !== null) {


                if (validateName === "cr676_alternate_ticket_contact_phone_number") {
                    if (AllternativeValidation.findIndex(item => item.index === index) === -1) {
                        validations.AllternativeValidation.push({
                            index,
                            AltcontactPhoneNumberV: checkPhoneNumber(value[index].cr676_alternate_ticket_contact_phone_number)
                        })
                    } else {
                        validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].AltcontactPhoneNumberV = checkPhoneNumber(value[index].cr676_alternate_ticket_contact_phone_number);

                    }
                }

                if (validateName === "cr676_alternate_ticket_contact_cell_number") {
                    if (AllternativeValidation.findIndex(item => item.index === index) === -1) {
                        validations.AllternativeValidation.push({
                            index,
                            AltTicketContactCellNumberV: checkPhoneNumber(value[index].cr676_alternate_ticket_contact_cell_number)
                        })
                    } else {
                        validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].AltTicketContactCellNumberV = checkPhoneNumber(value[index].cr676_alternate_ticket_contact_cell_number);

                    }
                }

                if (validateName === "cr676_alternate_ticket_contact_email") {
                    if (AllternativeValidation.findIndex(item => item.index === index) === -1) {
                        validations.AllternativeValidation.push({
                            index,
                            emailV: checkEmail(value[index].cr676_alternate_ticket_contact_email)
                        })
                    } else {
                        if (checkEmail(value[index].cr676_alternate_ticket_contact_email) && value[index].cr676_alternate_ticket_contact_email !== "") {
                            if (value.findIndex((item, index1) => index1 !== index && item.cr676_alternate_ticket_contact_email === value[index].cr676_alternate_ticket_contact_email) !== -1 || email === value[index].cr676_alternate_ticket_contact_email || emailaddress1 === value[index].cr676_alternate_ticket_contact_email) {
                                validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].emailV = "Already exiting";
                            } else {
                                validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].emailV = checkEmail(value[index].cr676_alternate_ticket_contact_email);
                            }
                        } else {
                            validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].emailV = checkEmail(value[index].cr676_alternate_ticket_contact_email);
                        }
                    }
                }
                if (validateName === "cr676_alternate_ticket_contact_password") {
                    if (AllternativeValidation.findIndex(item => item.index === index) === -1) {
                        if (value[index].cr676_alternate_ticket_contact_confirm_password !== '') {
                            if (checkPassword(value[index].cr676_alternate_ticket_contact_password) === true) {
                                validations.AllternativeValidation.push({
                                    index,
                                    passwordV: checkPasswordMatch(value[index].cr676_alternate_ticket_contact_password, value[index].cr676_alternate_ticket_contact_confirm_password)
                                })
                            } else {
                                validations.AllternativeValidation.push({
                                    index,
                                    passwordV: checkPassword(value[index].cr676_alternate_ticket_contact_password)
                                })
                            }
                        } else {
                            validations.AllternativeValidation.push({
                                index,
                                passwordV: checkPassword(value[index].cr676_alternate_ticket_contact_password)
                            })
                        }
                    } else {
                        if (value[index].cr676_alternate_ticket_contact_confirm_password !== '') {
                            if (checkPassword(value[index].cr676_alternate_ticket_contact_password) === true) {
                                validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].passwordV = checkPasswordMatch(value[index].cr676_alternate_ticket_contact_password, value[index].cr676_alternate_ticket_contact_confirm_password);
                            } else {
                                validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].passwordV = checkPassword(value[index].cr676_alternate_ticket_contact_password);
                            }
                        } else {
                            validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].passwordV = checkPassword(value[index].cr676_alternate_ticket_contact_password);
                        }
                    }
                }
                if (validateName === "cr676_alternate_ticket_contact_confirm_password") {
                    if (AllternativeValidation.findIndex(item => item.index === index) === -1) {
                        if (value[index].cr676_alternate_ticket_contact_confirm_password.length >= value[index].cr676_alternate_ticket_contact_password.length) {
                            validations.AllternativeValidation.push({
                                index,
                                confirmPasswordV: checkPasswordMatch(value[index].cr676_alternate_ticket_contact_password, value[index].cr676_alternate_ticket_contact_confirm_password)
                            })
                        } else {
                            if (checkPasswordMatch(value[index].cr676_alternate_ticket_contact_confirm_password, value[index].cr676_alternate_ticket_contact_password) == true) {
                                validations.AllternativeValidation.push({
                                    index,
                                    confirmPasswordV: checkPassword(value[index].cr676_alternate_ticket_contact_confirm_password)
                                })
                            } else {
                                validations.AllternativeValidation.push({
                                    index,
                                    confirmPasswordV: checkPasswordMatch(value[index].cr676_alternate_ticket_contact_password, value[index].cr676_alternate_ticket_contact_confirm_password)
                                })
                            }
                        }
                    } else {
                        if (value[index].cr676_alternate_ticket_contact_confirm_password.length >= value[index].cr676_alternate_ticket_contact_password.length) {
                            validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].confirmPasswordV = checkPasswordMatch(value[index].cr676_alternate_ticket_contact_confirm_password, value[index].cr676_alternate_ticket_contact_password);
                        } else {
                            if (checkPasswordMatch(value[index].cr676_alternate_ticket_contact_confirm_password, value[index].cr676_alternate_ticket_contact_password) == true) {
                                validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].confirmPasswordV = checkPassword(value[index].cr676_alternate_ticket_contact_confirm_password);
                            } else {
                                validations.AllternativeValidation[AllternativeValidation.findIndex(item => item.index === index)].confirmPasswordV = checkPasswordMatch(value[index].cr676_alternate_ticket_contact_confirm_password, value[index].cr676_alternate_ticket_contact_password);
                            }
                        }
                    }

                }
            }
            break;

        default:
            break;
    }
    return validations;
};

const updateProfileValidation = (validations, name, value) => {
    switch (name) {
        case 'cr676_key_ticket_contact_office_number':
            validations.officeNumberV = checkPhoneNumber(value);
            break;
        case 'cr676_key_ticket_contact_cell_number':
            validations.cellNumberV = checkPhoneNumber(value);
        default:
            break;
    }
    return validations;
};


const checkValidObeject = (validations) => {
    let check = []
    if (validations && validations.length > 0) {
        validations.map((item) => {
            if (Object.values(item).findIndex(i => typeof i === "string") !== -1) {
                check.push(item)
            }
            //  Object.value(item)
        })
        if (check.length > 0) {
            return true;
        }
    }
    return false;
};


const checkValid = (validations) => {
    if (validations) {
        const check = Object.keys(validations).filter(
            v => validations[[v]] !== null && validations[[v]] !== true ,
        );

        if (check.length > 0) {
            return true;
        }
    }
    return false;
};

const checkValid3 = (validations) => {
    let check = []
    if (validations) {
        Object.keys(validations).map((item) => {
            if (Array.isArray(validations[item])) {
                if (checkValidObeject(validations[item]) === true) {
                    check.push("object")
                }
            } else {
                if (validations[item] !== null && validations[item] !== true) {
                    check.push(validations)
                }
            }
        })
        // const check = Object.keys(validations).filter(
        //     v => (validations[[v]] !== null && validations[[v]] !== true)  (typeof validations[[v]] === "object" && checkValidObeject(validations[[v]]) !== true) 
        //     // console.log("sssss",validations, "validations", validations[[v]] !== null , validations[[v]] !== true,  "sddd", validations[[v]] !== null && validations[[v]] !== true && (typeof validations[[v]] === "object" && checkValidObeject(validations[[v]]) !== true), (typeof validations[[v]] === "object" && checkValidObeject(validations[[v]]) !== true) )

        //     // }
        // );

        if (check.length > 0) {
            return true;
        }
    }
    return false;
};


// && (typeof validations[[v]] === "object" && checkValidObeject(validations[[v]]) !== true  )
export {
    checkEmail,
    checkPassword,
    checkPasswordMatch,
    required,
    validate,
    validateStep2,
    validateStep3,
    validateLocation,
    updateProfileValidation,
    validateSharedModel,
    validateChangeReq,
    checkValid,
    checkValid3,
    validateStep2CheckBoxes
};
