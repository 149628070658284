import { stat } from "fs";

const initialState = {
    get_event_categories: {},
    get_featured_events: {},
    get_all_events: {},
    get_sorted_event: {},
    eventDetails: {},
    clientRequests: {},
    ticketAllocationRequests: {},
    agencyChangeRequests: {},
    modalType: null,
    modalProps: {},
    generateTicketRequest: {},
    insertKey: {},
    allocateTicket: {},
    totalReceivedAndAllocatedTickets: {},
	totalAgencyTicket: {},
    sendEmail: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_INITIAL_SIMPLE':
            return initialState;
        case 'GET_EVENT_CATEGORIES':
            state.get_event_categories = action.payload;
            return { ...state }
        case 'GET_EVENT_FEATURED_CATEGORIES':
            state.get_featured_events = action.payload;
            return { ...state }
        case 'GET_ALL_EVENTS':
            state.get_all_events = action.payload;
            return { ...state }
        case 'GET_EVENTS_SORTED':
            state.get_sorted_event = action.payload;
            return { ...state }
        case 'GET_EVENT_DETAILS':
            return { ...state, eventDetails: action.payload }
        case 'GET_EVENT_CLIENT_REQUESTS':
            return { ...state, clientRequests: action.payload }
        case 'GET_EVENT_TICKET_ALLOCATION_REQUESTS':
            return { ...state, ticketAllocationRequests: action.payload }
		case 'GET_CHANGE_AGENCY_REQUEST':
			return { ...state, getChangeAgencyRequest: action.payload }
        case 'GET_EVENT_AGENCY_REQUESTS':
            return { ...state, agencyRequests: action.payload }
		case 'DELETE_CHANGE_AGENCY_REQUEST':
		    return { ...state, deleteChangeAgency: action.payload }
        case 'REQUEST_TICKET_ALLOACATED':
            return { ...state, generateTicketRequest: action.payload }
        case 'SUBMIT_CHANGE_REQUEST':
            return { ...state, agencyChangeRequests: action.payload }
		 case 'SAVE_CHANGE_AGENCY_REQUEST':
            return { ...state, saveAgencyChangeRequests: action.payload }
        case 'UPDATE_BATCH_AGENCY_REQUESTS':
            return { ...state, updateBatchAgencyReq: action.payload }
        case 'INSERT_HEADER_AS_KEY':
            return { ...state, insertKey: action.payload }
        case 'GET_EXPERIENCE_AGENCY_REQUESTS':
            return { ...state, experienceAgencyRequest: action.payload }
        case 'GET_EXPERIENCE_CLIENT_REQUESTS':
            return { ...state, experienceClientRequest: action.payload }
        case 'GET_EXPERIENCE_TICKET_REQUESTS':
            return { ...state, experienceTicketRequest: action.payload }
        case 'TOTAL_RECEIVED_ALLOCATED_TICKET':
            return { ...state, totalReceivedAndAllocatedTickets: action.payload }
        case 'ALLOCATE_TICKET':
            return {...state, allocateTicket: action.payload}
		case 'MODIFY_TICKET_ALLOCATION':
            return {...state, modifyTicketAllocation: action.payload}
		 case 'UPDATE_BATCH_SHERED_REQUESTS':
		    return  {...state, updateSharedTicketAllocation: action.payload}
		 case 'SEND_EMAIL_ON_CHANGE_STATUS':
		    return  {...state, sendEmailOnStatusChange: action.payload}
		case 'TOTAL_AGENCY_TICKET':
		    return  {...state, totalAgencyTicket: action.payload}
	    case 'SEND_EMAIL':
            return { ...state, sendEmail: action.payload }	
		case 'DELETE_AGENCY_REQUESTS':
		     return { ...state, deleteAgency: action.payload }	
		case 'GET_REQUEST_TICKET':
		     return { ...state, totalRequestTicket: action.payload }	
		case 'GET_CLIENT_HOVER_DATA':
		     return { ...state, getClientData: action.payload }	 
        case 'SHOW_MODAL':
            return {
                modalType: action.modalType,
                modalProps: action.modalProps
            }
        case 'HIDE_MODAL':
            return initialState
        default:
            return state
    }
}
