/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter , Link} from 'react-router-dom';
import {DefaultInput, DefaultButton} from '../../../UI/atoms/index';
import {Col, Container, Form, FormGroup, Row} from 'reactstrap';
import { updateProfileValidation, checkValid } from '../../../../utils/validations';
import {resetPassword, updateDynamicUser, fetchDynamicUser} from "../../../../actions/AuthActions";
import { Icon } from './../../../UI/atoms/Icon';
import {connect} from "react-redux";
import { toast } from 'react-toastify';

class Profile extends React.Component {
  constructor(props) {
    super(props)
	 this.state = {
		      formData: {
			  firstname: '',
			  lastname:'',
			  middlename:'',
			  cr676_key_ticket_contact_position:'',
			  cr676_key_ticket_contact_office_number:'',
			  cr676_key_ticket_contact_cell_number:'',
			  emailaddress1: ''
			  },
			  validationProfile: {
					officeNumberV: null,
					cellNumberV: null
				}
			  
    }
  }
  
 

  handleChange(e) {
	  e.preventDefault();
	 const { name, value } = e.target;
	 const {validationProfile} = this.state;
	   this.setState((prevState) => ({
      formData: {
        // object that we want to update
        ...prevState.formData, // keep all other key-value pairs
		[name] : value 
	  },
	  validationProfile: updateProfileValidation(validationProfile, name, value)
	}));

}

checkValidationProfile = () => {
    const { validationProfile } = this.state;
    const check = checkValid(validationProfile);
    if (check) {
      return true;
    }
    return false;
  };
      
	submitForm = (e) => {
          e.preventDefault();
		  let props = this.props;
		  console.log('user', props.user);
		  const {formData} = this.state;
		  if (props.user) {
			    props.updateDynamicFn(props.user.data, props.dynamic, formData);
		  }
	  }
     
	 componentDidMount () {
		 console.log('props dynamics>>', this.props);
		if (this.props.dynamic) {
		   this.setState({
			  formData: { 
              firstname: this.props.dynamic.firstname,
			  middlename: this.props.dynamic.middlename,
			  lastname: this.props.dynamic.lastname,
			  cr676_key_ticket_contact_position: this.props.dynamic.cr676_key_ticket_contact_position,
			  cr676_key_ticket_contact_office_number: this.props.dynamic.cr676_key_ticket_contact_office_number,
			  cr676_key_ticket_contact_cell_number: this.props.dynamic.cr676_key_ticket_contact_cell_number,
			  emailaddress1: this.props.dynamic.emailaddress1
			  }
        })
			
		} 
	 }
    componentWillReceiveProps(props) {
		console.log('props',props);
		if (props.udate_dynamic) {
			if (props.udate_dynamic.status === 204) {
				
				toast.success('User update successfully');
			}
		}
		
		if (props.dynamic) {
		   this.setState({
			  formData: { 
              firstname: props.dynamic.firstname,
			  middlename: props.dynamic.middlename,
			  lastname: props.dynamic.lastname,
			  cr676_key_ticket_contact_position: props.dynamic.cr676_key_ticket_contact_position,
			  cr676_key_ticket_contact_office_number: props.dynamic.cr676_key_ticket_contact_office_number,
			  cr676_key_ticket_contact_cell_number: props.dynamic.cr676_key_ticket_contact_cell_number,
			  emailaddress1: props.dynamic.emailaddress1
			  }
        })
			
		} else{
			this.props.fetchDynamicUserFn(props.user.data,props.dynamic );
		}
		
  }

  render() {
	  const {formData: {firstname,middlename,lastname, cr676_key_ticket_contact_position, cr676_key_ticket_contact_office_number,cr676_key_ticket_contact_cell_number, emailaddress1}, 
	  validationProfile: {officeNumberV,cellNumberV }} = this.state;
	
    return (
        <div className="dashboard">
          <Container fluid={true}>
              <div className="dashboard-heading">
                <span className="section-icon">
                  <Icon name="user"/>
                </span>
                <h4>MY PROFILE</h4>
              </div>

            <Row className="dashboard-white-outer">
              <Col className="dashboard-content-left col-xxl-3" xs="12" sm="12" md="12" lg="12" xl="4">
			  
              <FormGroup>
			   <label>First Name</label>
                <DefaultInput
                      name="firstname"
                      type="text"
					  value= {firstname}
                      onChange={this.handleChange.bind(this)}
                      placeholder="First Name"
                  />
              </FormGroup>
			   <FormGroup>
			   <label>Middle Name</label>
                <DefaultInput
                      name="middlename"
                      type="text"
					  value= {middlename}
                      onChange={this.handleChange.bind(this)}
                      placeholder="Middle Name"
                  />
              </FormGroup>
			  
			     <FormGroup>
				  <label>Last Name</label>
                <DefaultInput
                      name="lastname"
                      type="text"
					  value= {lastname}
                      onChange={this.handleChange.bind(this)}
                      placeholder="Last Name"
                  />
              </FormGroup>
			  
			
			{/* <FormGroup>
                <DefaultInput
                      name="cr676_key_ticket_contact_position"
                      type="text"
					  value= {cr676_key_ticket_contact_position}
                       onChange={this.handleChange.bind(this)}
                      placeholder="Position"
                  />
              </FormGroup>
              <FormGroup>
                <DefaultInput
                      name="cr676_key_ticket_contact_office_number"
                      type="number"
					  value= {cr676_key_ticket_contact_office_number}
                      onChange={this.handleChange.bind(this)}
					  validation={officeNumberV}
                      placeholder="Office Number"
                  />
              </FormGroup>
              <FormGroup>
                <DefaultInput
                      name="cr676_key_ticket_contact_cell_number"
                      type="number"
					  value= {cr676_key_ticket_contact_cell_number}
                      onChange={this.handleChange.bind(this)}
					  validation={cellNumberV}
                      placeholder="Cell Number"
                  />
			</FormGroup> */}
              <FormGroup>
			   <label>Email</label>
                <DefaultInput
                      name="name"
                      type="email"
					  readonly
					   value= {emailaddress1}
                      // onChange={this.handleChange}
                      placeholder="Email Address"
                  />
              </FormGroup>
              <FormGroup>
              <DefaultButton
			        onClick={this.submitForm}
					disabled={this.checkValidationProfile()}
                    name="UPDATE"
                    color="primary"
                />
              </FormGroup>
              </Col>
            </Row>
          </Container>
        </div>
    );
  }
}
 function mapStateToProps(state) {
   return {
    dynamic: state.authReducer.dynamic_user_detail,
	user: state.authReducer.user,
	udate_dynamic: state.authReducer.udate_dynamic
  };
  }
  
    const mapDispatch = dispatch => ({
    updateDynamicFn: (user, dynamic, data) => dispatch(updateDynamicUser(user, dynamic, data)),
	fetchDynamicUserFn:( user, dynamic )=> dispatch(fetchDynamicUser(user, dynamic)) 
	
  });
export default withRouter(connect(mapStateToProps, mapDispatch)(Profile));


