const initialState = {
    agencyData: [],
    dashboard: [],
	clientGraphData:[],
	agencyFilterGraphData: [],
	agencyPieGraphData: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_AGENCY_DASHBOARD_DATA':
			return { ...state, agencyData: action.payload }
		case 'GET_DATA':
			return { ...state, dashboard: action.payload }
		case 'GET_CLIENT_FILTER_DATA':
			return { ...state, clientGraphData: action.payload }
		case 'GET_AGENCY_GRAPH_DATA':
			return { ...state, agencyFilterGraphData: action.payload }
		case 'GET_CLIENT_LINE_GRAPH_DATA':
			return { ...state, clientLineGraphData: action.payload }
		case 'GET_AGENCY_LINE_GRAPH_DATA':
			return { ...state, agencyLineGraphData: action.payload }
		case 'GET_CLIENT_PROCESSED_GRAPH_DATA':
			return { ...state, clientProcessedGraphData: action.payload }
		case 'GET_CLIENT_ALLOCATED_GRAPH_DATA':
			return { ...state, clientAllocatedGraphData: action.payload }
		case 'SAVE_EXPAND_MENU_STATUS':
			return { ...state, expandMenuStatus: action.payload }
		case 'GET_AGENCY_PIE_GRAPH_DATA':
			return { ...state, agencyPieGraphData: action.payload }
		default:
			return { ...state }
	}
}