import React, { useState } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { DefaultInput } from './../../../UI/atoms/index';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { validate } from "../../../../utils/validations";
import { flatMap } from 'lodash';

class GenerateTicketRequestModalLimited extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adultTickets: 0,
            childrenTickets: 0,
            totalTickets: 0,
            comment: '',
        }
    }


    handleChange = (e) => {
        const { name, value } = e.target;
        // console.log(name);
        if (name === 'adultTickets') {
            this.setState({ adultTickets: value });
        }
        else if (name === 'childrenTickets') {
            this.setState({ childrenTickets: value }, () => {
                this.totaCheck()
            });
        }

        else if (name === 'comment') {
            this.setState({ comment: value }, () => {
                this.totaCheck()
            });
        }
    };

    totaCheck = () => {
        let total = parseInt(this.state.adultTickets) + parseInt(this.state.childrenTickets);
        this.setState({ totalTickets: total });
    }

    isSubmitDisable() {
        const { adultTickets, childrenTickets } = this.state
        if ((adultTickets === 0 || adultTickets === "" || !adultTickets) && (childrenTickets === 0 || childrenTickets === "" || !childrenTickets))
            return true
        else
            return false
    }

    render() {
        const { adultTickets, childrenTickets, totalTickets, comment } = this.state;
        const { data, onSubmitGenerateRequest, onCancel } = this.props;

        return (
            <Modal isOpen={true} className="event-popup modal-dialog-centered">
                <ModalHeader>Generate Experience Request</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <Label for="numberAdults">Adult Experiences</Label>
                                <DefaultInput
                                    name="adultTickets"
                                    type="number"
                                    value={adultTickets}
                                    placeholder="Adult Experiences"
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <Label for="numberChildren">Children Experiences</Label>
                                <DefaultInput
                                    name="childrenTickets"
                                    type="number"
                                    value={childrenTickets}
                                    placeholder="Children Experiences"
                                    onChange={this.handleChange}

                                />
                            </div>
                            <div className="form-group">
                                <Label for="totalTickets">Total Experiences</Label>
                                <DefaultInput
                                    name="totalTickets"
                                    type="number"
                                    value={totalTickets}
                                    placeholder="Total Experiences"
                                    readOnly
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <Label for="comment">Comments</Label>
                            <DefaultInput
                                name="comment"
                                type="text"
                                value={comment}
                                placeholder="add comments here (if any)"
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary"
                        disabled={this.isSubmitDisable()}
                        onClick={(e) => onSubmitGenerateRequest({
                            comment,
                            adultTickets,
                            childrenTickets,
                            totalTickets
                        })}>Submit</Button>{' '}
                    <Button color="secondary" onClick={(e) => onCancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default withRouter(GenerateTicketRequestModalLimited);