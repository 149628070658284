/*eslint eqeqeq: "off"*/
import React from 'react';
import { Input } from 'reactstrap';

export const DefaultInput = ({ name, onChange, disabled, className,placeholder, loading, type, value, validation , label}) => {
  let error = validation;
  if (typeof validation === 'object' && validation !== null) {
    if (typeof validation[[name]] !== 'undefined') {
      error = validation[[name]].msg;
    }
  }

  return (
      <>
      <Input
          className={`${className}`}
          onChange={onChange}
          disabled={disabled || loading}
          type={type}
          value={value || ''}
          name={name}
          placeholder={placeholder}
      />
        <div className="error clearfix">{error}</div>
      </>
  );
}

DefaultInput.defaultProps = {
  validation: null,
  disabled: false,
  className: '',
  loading: false,
  onChange: () => { }
};

