/*eslint eqeqeq: "off"*/
import React, { Component } from "react";
import { connect } from "react-redux";
import GuestMain from "../templates/guest/GuestMain";
import UserMain from "../templates/user/UserMain";
import { withRouter } from "react-router-dom";
import { fetchUser, loginToken } from "../../actions/AuthActions";
import {
  APP_DYNAMICS_RESOURCE,
  DYNAMICS_CLIENT_ID,
  DYNAMICS_CLIENT_SECRET,
  DYNAMICS_GRANT_TYPE
} from "../../utils/constants";
// import ReactLoader from "react-loader-spinner";
import toBoolean from "validator/es/lib/toBoolean";
import Loader from "../UI/molecules/Loader";


let itemInterval = false;
export default function(ComposedComponent) {
  class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.interval = false;
      this.state = {
        interval: false
      }
    }

    componentDidMount() {
      var backend_token = localStorage.getItem("token");
      if (backend_token) {
        var payload = { token: backend_token };
		if (this.props.user === null) {
			this.props.fetchUserFn(payload);
		}
      }

      let self = this;
     setInterval(() => {
		 if (localStorage.hasOwnProperty('loader')) {
			 let interval = toBoolean(localStorage.getItem("loader"));
             this.setState({interval})
             itemInterval = interval;
		 }
       
      },1000) 
    }

    render() {
      const Root = this.props.user || localStorage.getItem("token")? (
        <UserMain children={<ComposedComponent {...this.props} />} />
      ) : (
        <GuestMain children={<ComposedComponent {...this.props} />} />
      );

      // const isShowingLoader = toBoolean(localStorage.getItem("loader") || "") || this.props.isShowingLoader;
      // const isShowingLoader = toBoolean(localStorage.getItem("loader"));
      return (
        <>
          <Loader isVisible={this.state.interval} />
          {Root}
        </>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      user: state.authReducer.user,
      // isShowingLoader: state.ui.isShowingLoader
    };
  }

  const mapDispatch = dispatch => ({
    fetchUserFn: user => dispatch(fetchUser(user)),
    tokenUserFn: dynamic => dispatch(loginToken(dynamic))
  });

  return withRouter(connect(mapStateToProps, mapDispatch)(Wrapper));
}
