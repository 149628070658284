/*eslint eqeqeq: "off"*/
import React from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import DefaultButton from "../atoms/DefaultButton";
import {set_initial_auth} from "../../../actions/AuthActions";
import {toast} from "react-toastify";

class Logout extends React.Component {

    constructor(props) {
        super(props)
    }

    logout = () => {
        this.props.set_initial_auth();
        localStorage.clear();
        this.props.history.push("/");
        toast.success('user logout successfully');
    }

    render() {
        return (
                <DefaultButton
                    name="Logout"
                    color="secondary"
                    onClick={this.logout}
                    block={true}
                />
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.authReducer.user
    };
};

const mapDispatchToProps = dispatch => ({
    set_initial_auth: () => dispatch(set_initial_auth())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
