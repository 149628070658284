/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import { numberFormat } from '../../../UI/molecules/NumberFormat'

import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Icon } from './../../../UI/atoms/Icon';
import {
  getExperienceAgencyRequest
} from '../../../../actions/EventActions';

class agencyRequest extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    if (this.props.agencyGuid) {
      this.props.getExperienceAgencyRequest(this.props.agencyGuid);
    }
  }

  tripleHeader = ({ string1, string2, string3 }) => {

    return (
      <table width="100%" className="innerHeading">
        <tr>
          <td>{string1}</td>
          <td>{string2}</td>
          <td>{string3}</td>
        </tr>
      </table>
    )
  }
  singleHeader = ({ string1 }) => {

    return (
      <table width="100%" className="innerHeading">
        <tr>
          <td>{string1}</td>
        </tr>
      </table>
    )
  }
  totalTicketAllow = (string1, string2, string3) => {
    return (
      <table className="double-conent">
        <tbody>
          <td>{string1}</td>
          <td>{string2} </td>
          <td>{string3} </td>
        </tbody>
      </table>
    );
  }

  singleTicketAllow = (string1, eventId) => {
    return (
      <Link to={{ pathname: `/agency/event-detail/${eventId}`, state: { tab: "2" } }} > {string1}</Link>
    );
  }

  agencyData = () => {
    const sortedAgencyRequest = this.props.experienceAgencyRequest ? this.props.experienceAgencyRequest.sort((a, b) => new Date(b.createdon) - new Date(a.createdon)) : '';
    const agencyReq = sortedAgencyRequest ? sortedAgencyRequest.map((req, index) => {
      const eventId = req._evtech_eventid_value || '';
      const totalAgencyReq = req.evtech_totaltickets || 0;
      const totalAdultAgencyReq = req.evtech_numberofchaperones || 0;
      const totalChildrenAgencyReq = req.evtech_numberofchildren || 0;

      const totalAdultAgencyReceived = req.cr676_confirmed_adult_tickets || '-'
      const totalChildrenAgencyReceived = req.cr676_confirmed_children_tickets || '-'
      const totalAgencyReceived = req.evtech_confirmedtickets || "-"

      // const totalAgencyReceived = (req.cr676_confirmed_children_tickets && req.cr676_confirmed_adult_tickets) ? Number(req.cr676_confirmed_children_tickets) + Number(req.cr676_confirmed_adult_tickets) : (req.cr676_confirmed_children_tickets || req.cr676_confirmed_adult_tickets) ? req.cr676_confirmed_children_tickets || req.cr676_confirmed_adult_tickets : "-"

      return {
        name: req.cr676_event_name,
        eventId: eventId,
        requestedBy: req.cr676_requested_by_name ? req.cr676_requested_by_name : (req.evtech_agencyid ? req.evtech_agencyid.name : ''),
        totalAgencyReq,
        totalAdultAgencyReq,
        totalChildrenAgencyReq,

        totalAdultAgencyReceived,
        totalChildrenAgencyReceived,
        totalAgencyReceived,

        received: req.evtech_quanityassigned || 0,
        amount: req.evtech_totalticketvalue || 0
      }
    }) : [];

    agencyReq.unshift({
      name: true,
      requestedBy: '',
      requested: true,
      received: ''
    });
    return agencyReq;
  }

  columnAgency = [
    {
      dataField: 'name',
      text: 'Event Name',
      sort: true,
      formatter: (col, row) => {
        if (row.name == true) {
          return '';
        }
        else {
          return this.singleTicketAllow(row.name, row.eventId);
        }
      }
    },
    {
      dataField: 'requestedBy',
      text: 'Requested By',
      sort: true
    },
    {
      dataField: 'requested',
      text: 'Requested',
      formatter: (col, row) => {
        if (row.requested == true) {
          return this.tripleHeader({
            string1: "Adult",
            string2: "Children",
            string3: "Total"
          });
        }
        else {
          return this.totalTicketAllow(row.totalAdultAgencyReq, row.totalChildrenAgencyReq, row.totalAgencyReq);
        }
      }
    },
    {
      dataField: 'received',
      text: 'Received',
      formatter: (col, row) => {
        if (row.requested == true) {
          return this.singleHeader({
            // string1: "Adult ",
            // string2: "Children",
            string1: "Total"
          });
        }
        else {
          return this.totalTicketAllow(row.totalAgencyReceived, false, row.ticketRequestId);

          // return this.totalTicketAllow(row.totalAdultAgencyReceived, row.totalChildrenAgencyReceived, row.totalAgencyReceived, false, row.ticketRequestId);
        }
      },
    },
    {
      dataField: 'amount',
      text: 'Amount Received',
      formatter: (col, row) => { return (col != null) ? numberFormat(col) : "" },
      sort: true
    }
  ];

  render() {

    return (
      <div className="dashboard">
        <Container fluid={true}>
          <div className="dashboard-heading">
            <span className="section-icon">
              <Icon name="list" />
            </span>
            <h4>Agency Request</h4>
          </div>
          <Row>
            <Col sm="12">
              <div className="event-table exp-agency-request">
                <BootstrapTable keyField='id' data={this.agencyData()} columns={this.columnAgency} pagination={paginationFactory()} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    agencyGuid: state.authReducer.user && state.authReducer.user.data.guid,
    experienceAgencyRequest: state.eventReducer.experienceAgencyRequest && state.eventReducer.experienceAgencyRequest.value,
  }
};

const mapDispatchTpProps = (dispatch) => ({
  getExperienceAgencyRequest: (agencyGuid) => dispatch(getExperienceAgencyRequest(agencyGuid))
});

export default withRouter(connect(mapStateToProps, mapDispatchTpProps)(agencyRequest));