import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux'
import rootReducer from './reducers/rootReducer';

export const history = createHistory()
const initialState = {};
const enhancers = [];

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state.authReducer)
        localStorage.setItem('authReducer', serializedState)
    } catch (ex) {
        console.log('error: ', ex)
    }
}

const middleware = [
    thunk,
    routerMiddleware(history)
];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension
    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const persistedState = loadFromLocalStorage();
const configureStore = createStore(
    rootReducer,
    persistedState,
    composedEnhancers
);

configureStore.subscribe(() => saveToLocalStorage(configureStore.getState()))

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('authReducer')
        if (serializedState === null) 
            return {}
    
        return { authReducer: JSON.parse(serializedState) }
    } catch (ex) {
        console.log('error: ', ex)
    }
}

export default configureStore