import React from 'react';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';
 
export const Icon = ({name, size}) =>{
    return (
      <FontAwesome
        name={name}
        size={size}
      />
    )
}

