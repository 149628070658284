import { callApi } from "../utils/apiCaller";
export const GET_CATEGORIES = 'GET_CATEGORIES';

// GET_CATEGORIES
export const getCategories_ = (payload) => {
    return {
        type: GET_CATEGORIES,
        payload
    }
}

export const getCategories = () => {
    return (dispatch) => {
        callApi(`/all-retailers`, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(getCategories_({a:1,b:2}));
                }
            })
    }
}