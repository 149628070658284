import React from 'react';
import Select from 'react-select';
export default class FilterDropdown extends React.Component {
    constructor(props) {
   super(props);
	}

    render() {
		const {onChangeEvent, filterDataValue, selectValue, filterGraphDataOptions} = this.props; 
		const filterGraphDataOption = filterGraphDataOptions && filterGraphDataOptions.filter(data => data.value !== 2018);

        return (
            <div className="mb-3 ml-auto" style={{maxWidth: "200px"}}>
                <Select 
                value={filterGraphDataOption && filterGraphDataOption.filter(option => option.value === selectValue) }
				options={filterGraphDataOption} onChange={(event) => onChangeEvent(event)}/>
            </div>
          
        );
    }
}

