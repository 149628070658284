
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header,Sidebar } from '.';
import {connect} from "react-redux";
import { fetchUser, loginToken } from '../../../actions/AuthActions';
import { DYNAMICS_CLIENT_ID, DYNAMICS_GRANT_TYPE,DYNAMICS_CLIENT_SECRET, APP_DYNAMICS_RESOURCE } from "../../../utils/constants";
import Loader from "react-loader-spinner";
import toBoolean from "validator/es/lib/toBoolean";
import sidebar from './sidebar';
import $ from "jquery";

class UserMain extends React.Component {
  state = {
    isLoader: null,
    sidebarStatus: true
  }

  componentDidMount() {
      mergeSubHeader();
      $(".react-bootstrap-table #select-filter-column-status").on('change',function(){
          var selectedVal = $(this).children("option:selected").val();
          if(selectedVal == '') {
              // setTimeout(function(){  removeExtraSubHeader(); }, 3000);
          }
      });

      function mergeSubHeader() {
         var subheader_tr1 = $('.exp-client-request').find("tbody").find("tr:eq(0)").wrap('<p/>').parent().html();
          $('.exp-client-request').find('thead').append(subheader_tr1);
          $('.exp-client-request').find("tbody").find("p:eq(0)").remove();

          var subheader_tr2 = $('.exp-agency-request').find("tbody").find("tr:eq(0)").wrap('<p/>').parent().html();
          $('.exp-agency-request').find('thead').append(subheader_tr2);
          $('.exp-agency-request').find("tbody").find("p:eq(0)").remove();

          var subheader_tr3 = $('.exp-ticket-allocation').find("tbody").find("tr:eq(0)").wrap('<p/>').parent().html();
          $('.exp-ticket-allocation').find('thead').append(subheader_tr3);
          $('.exp-ticket-allocation').find("tbody").find("p:eq(0)").remove();


          var subheader_tr4 = $('.event-client-request').find("tbody").find("tr:eq(0)").wrap('<p/>').parent().html();
          $('.event-client-request').find('thead').append(subheader_tr4);
          $('.event-client-request').find("tbody").find("p:eq(0)").remove();

          var subheader_tr5 = $('.event-agency-request').find("tbody").find("tr:eq(0)").wrap('<p/>').parent().html();
          $('.event-agency-request').find('thead').append(subheader_tr5);
          $('.event-agency-request').find("tbody").find("p:eq(0)").remove();

          var subheader_tr6 = $('.event-ticket-allocation').find("tbody").find("tr:eq(0)").wrap('<p/>').parent().html();
          $('.event-ticket-allocation').find('thead').append(subheader_tr6);
          $('.event-ticket-allocation').find("tbody").find("p:eq(0)").remove(); 
	
      }

      function removeExtraSubHeader() {
         $('.exp-client-request').find("tbody").find("tr:eq(0)").remove();
          $('.exp-agency-request').find("tbody").find("tr:eq(0)").remove();
          $('.exp-ticket-allocation').find("tbody").find("tr:eq(0)").remove();
          $('.event-client-request').find("tbody").find("tr:eq(0)").remove();
          $('.event-agency-request').find("tbody").find("tr:eq(0)").remove();
          $('.event-ticket-allocation').find("tbody").find("tr:eq(0)").remove();
	
      }
  }

    componentWillMount() {
      var backend_token = localStorage.getItem('token');
      var payload = {'token': backend_token};
      // this.props.fetchUserFn(payload);
  }


    sidebarStatus = async(sbStatus) =>{
      await this.setState({sidebarStatus: sbStatus});
    }


    render() {


    const { children, user } = this.props;
	// const {isLoader} = this.state;
    return (

      <div className={"user-dashboard " + (this.state.sidebarStatus ? "sidebar-open" : "sidebar-collapse")}>
          {/*{this.checkIsLoader(isLoader)}*/}
          <Header />
          <Sidebar status={this.sidebarStatus}/>
          <section>
          { user ? children: ''}
          </section>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
	user: state.authReducer.user && state.authReducer.user.data,
  }
}

const mapDispatch = (dispatch) => ({
    fetchUserFn: user => dispatch(fetchUser(user))
});

export default withRouter(connect(mapStateToProps,mapDispatch)(UserMain));
