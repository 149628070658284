/*eslint eqeqeq: "off"*/
import React from 'react';
// import { Container, Row, Col, TabContent, TabPane, NavLink,Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { Redirect, withRouter, Link } from 'react-router-dom';
import windowSize from 'react-window-size';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { toast } from "react-toastify";
import { set_initial_auth } from "../../../actions/AuthActions";
import { saveExpandMenuStatus } from "../../../actions/DashboardActions";
import { connect } from "react-redux";
import Profile from "./../../../assets/images/user.png";
import Star from "./../../../assets/images/star.png";
import Coupon from "./../../../assets/images/coupon.png";
import events from "./../../../assets/images/events.png";

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    }
  }

  expendedStatus = async (status) => {
    console.log('here>>>', status);
    await this.props.saveExpandMenuStatus(status);
    await this.setState({ expanded: status });
    await this.props.status(this.state.expanded);
  }

  logout = (e) => {
    e.preventDefault();
    this.props.set_initial_auth();
    localStorage.clear();
    // this.props.history.push("/");
    toast.success('user logout successfully');
  }
  /**
* Calculate & Update state of new dimensions
*/
  async updateDimensions() {
    if (window.innerWidth < 1000) {
      await this.props.saveExpandMenuStatus(false);
      this.setState({
        expanded: false
      });
      await this.props.status(false);

    } else {
      await this.props.saveExpandMenuStatus(true);
      this.setState({
        expanded: true
      });
      await this.props.status(true);
    }
  }
  /**
  * Remove event listener
  */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  componentDidMount() {
    console.log('here go>>>', this.props.expandMenuStatus);
    console.log(this.props.windowWidth);
    if (this.props.windowWidth < 1000) {
      this.setState({
        expanded: false
      });
    } else {
      this.setState({
        expanded: true
      });
    }
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillReceiveProps() {
    // console.log("window.innerWidth", window.innerWidth, this.props.windowWidth, window.screen.width)
    /* if (this.props.windowWidth < 1000) {
       this.setState({
         expanded: (this.props.expandMenuStatus !== undefined ) ? this.props.expandMenuStatus : false
       });
     } else {
       this.setState({
         expanded:  (this.props.expandMenuStatus !== undefined ) ? this.props.expandMenuStatus : true
       });
     } */
  }

  render() {

    const { status, expandMenuStatus } = this.props;
    const userPermission = this.props.user ? this.props.user.data && this.props.user.data.userPermission : null;

    return (

      <div className="sidebar-custom">
        <SideNav
          expanded={(expandMenuStatus !== undefined) ? expandMenuStatus : this.state.expanded}
          onSelect={(selected) => {
            const to = '/' + selected;
            this.props.history.push(to);
          }}
          onToggle={(expanded) => {
            this.expendedStatus(expanded);
          }}
        >
          <SideNav.Toggle />
          <SideNav.Nav defaultSelected="home" >

            <NavItem eventKey="profile">
              <NavIcon>
                <img src={Profile} alt="ICon" />
              </NavIcon>
              <NavText>
                My Profiles
                </NavText>
              <NavItem eventKey="agency/profile">
                <NavText>
                  Change Profile
                  </NavText>
              </NavItem>
              <NavItem eventKey="agency/change-password">
                <NavText>
                  Change Password
                  </NavText>
              </NavItem>
              <NavItem onClick={this.logout}>
                <NavText>
                  Logout
                  </NavText>
              </NavItem>
            </NavItem>


            <NavItem eventKey="agency/dashboard">
              <NavIcon>
                <img src={Coupon} alt="ICon" />
              </NavIcon>
              <NavText>
                Dashboard
                </NavText>
              {/*    <NavItem eventKey="agency/dashboard">
                  <NavText>
                    Dashboard
                  </NavText>
                </NavItem>*/}

            </NavItem>
            <NavItem eventKey="agency/events">
              <NavIcon>
                <img src={events} alt="ICon" />
              </NavIcon>
              <NavText>
                Events
                </NavText>
              {/*  <NavItem eventKey="agency/events">
                  <NavText>
                    Calendar
                  </NavText>
                </NavItem>*/}


            </NavItem>
            <NavItem eventKey="experience">
              <NavIcon>
                <img src={Star} alt="ICon" />
              </NavIcon>
              <NavText>
                Experience
                </NavText>
              {userPermission &&
                <NavItem eventKey="experience/client-request">
                  <NavText>
                    Client Request
                  </NavText>
                </NavItem>
              }
              <NavItem eventKey="experience/agency-request">
                <NavText>
                  Agency Request
                  </NavText>
              </NavItem>
              {userPermission &&
                <NavItem eventKey="experience/ticket-allocation">
                  <NavText>
                    Experiences Allocation
                  </NavText>
                </NavItem>
              }
            </NavItem>

          </SideNav.Nav>
        </SideNav>
      </div>


    );
  }
}

const mapStateToProps = state => {
  return {
    dynamic: state.authReducer.dynamic_user_detail,
    user: state.authReducer.user,
    expandMenuStatus: state.dashboard.expandMenuStatus
  };
};

const mapDispatchToProps = dispatch => ({
  set_initial_auth: () => dispatch(set_initial_auth()),
  saveExpandMenuStatus: (status) => dispatch(saveExpandMenuStatus(status))
});

export default withRouter(windowSize(connect(mapStateToProps, mapDispatchToProps)(Sidebar)));

// export default withRouter(Sidebar);

