
/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';

import {
  FormGroup,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { Icon } from './../../../UI/atoms/Icon';
import {
  getExperienceTicketRequest
} from '../../../../actions/EventActions';
import {numberFormat} from "../../../UI/molecules/NumberFormat";

class ticketRequest extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    if (this.props.agencyCode) {
      this.props.getExperienceTicketRequest(this.props.agencyCode);
    }
  }

  tripleHeader = ({ string1, string2, string3 }) => {

    return (
      <table width="100%" className="innerHeading">
        <tr>
          <td>{string1}</td>
          <td>{string2}</td>
          <td>{string3}</td>
        </tr>
      </table>
    )
  }

  totalTicketAllow = (string1, string2, string3) => {
    return (
      <table className="double-conent">
        <tbody>
          <td>{string1}</td>
          <td>{string2} </td>
          <td>{string3} </td>
        </tbody>
      </table>
    );
  }
  
 singleTicketAllow = (string1, eventId) => {
return (
	  <Link to ={{  pathname: `/agency/event-detail/${eventId}`,  state: {  tab: "3" }}} > {string1}</Link>
);
}

  ticketData = () => {
    const sortedTicketRequest = this.props.experienceTicketRequest ? this.props.experienceTicketRequest.sort((a, b) => new Date(b.createdon) - new Date(a.createdon)) : '';
    const ticketReq = sortedTicketRequest ? sortedTicketRequest.map((req, index) => {
	  const eventId = req._evtech_eventid_value || '';   
      const totalRequestSend = req.evtech_totaltickets || 0;
      const adultRequestSend = req.evtech_numberofchaperones || 0;
      const childRequestSend = req.evtech_numberofchildren || 0;

      const totalRequestAllocation = req.cr676_total_allocated || 0;
      const adultRequestAllocation = req.cr676_adult_allocate || 0;
      const childRequestAllocation = req.cr676_children_allocate || 0;
	  const amount = req.evtech_totalticketvalue || 0;

      return {
        name: req.cr676_event_name || '',
		eventId: eventId,
        requestedBy: req.evtech_chaperonename || '',
        totalRequestSend,
        adultRequestSend,
        childRequestSend,
        totalRequestAllocation,
        adultRequestAllocation,
        childRequestAllocation,
        received: req.evtech_confirmedtickets || '',
		amount: amount
      }
    }) : [];

    ticketReq.unshift({
      name: true,
      requestedBy: '',
      requestSend: true,
      allocated: true,
      received: ''
    });
    return ticketReq;
  }
  
      columnTicket = [
       {
        dataField: 'name',
        text: 'Event Name',
        sort: true,
		formatter: (col, row) => {
	    if (row.name == true) {
		  return '';
		  }
		  else {
			return this.singleTicketAllow(row.name, row.eventId);
		  }
		}	
      },
      {
        dataField: 'requestedBy',
        text: 'Requested By',
        sort: true
      },
      {
        dataField: 'requestSend',
        text: 'Requested Sent',
        formatter: (col, row) => {
          if (row.requestSend == true) {
            return this.tripleHeader({
              string1: "Adult",
              string2: "Children",
              string3: "Total"
            });
          }
          else {
            return this.totalTicketAllow(row.adultRequestSend, row.childRequestSend, row.totalRequestSend);
          }
        },
      },
      {
        dataField: 'allocated',
        text: 'Allocation',
        formatter: (col, row) => {
          if (row.allocated == true) {
            return this.tripleHeader({
              string1: "Adult",
              string2: "Children",
              string3: "Total"
            });
          }
          else {
            return this.totalTicketAllow(row.adultRequestAllocation, row.childRequestAllocation, row.totalRequestAllocation);
          }
        },
      },
	 {
        dataField: 'amount',
        text: 'Amount Allocated',
       formatter: (col, row) => { return (col != null ) ? (col !== 0) ? numberFormat(col): 'Not Yet Allocated' :  '' }
       
      },
    ];

  render() {
    return (
      <div className="dashboard">
        <Container fluid={true}>
          <div className="dashboard-heading">
            <span className="section-icon">
              <Icon name="list" />
            </span>
            <h4>Experiences Allocation</h4>
          </div>
          <Row>
            <Col sm="12">
              <div className="event-table exp-ticket-allocation">
                <BootstrapTable keyField='id' data={this.ticketData()} columns={this.columnTicket} pagination={ paginationFactory() }/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    agencyCode: state.authReducer.user && state.authReducer.user.data.agency_code,
    experienceTicketRequest: state.eventReducer.experienceTicketRequest && state.eventReducer.experienceTicketRequest.value,
  }
};

const mapDispatchTpProps = (dispatch) => ({
  getExperienceTicketRequest: (agencyCode) => dispatch(getExperienceTicketRequest(agencyCode))
});

export default withRouter(connect(mapStateToProps, mapDispatchTpProps)(ticketRequest));