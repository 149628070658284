/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { DefaultInput, DefaultButton } from '../../UI/atoms/index';
import { Container, Form, FormGroup } from 'reactstrap';
import Logo from './../../../assets/images/logo.jpg';
import { validate, checkValid } from '../../../utils/validations';
import { resetPassword } from "../../../actions/AuthActions";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formValues: {
        password: '',
        confirm_password: '',
        token: '',
      },
      validations: {
        passwordV: null,
        password_confirmationV: null,
      },
      loading: false,
    }
  }

  submitResetPassword = (e) => {
    e.preventDefault();
    this.submitResetPasswordFn()
  };

  submitResetPasswordFn = async () => {
    const { formValues } = this.state;
    const { history } = this.props;
    await resetPassword(formValues, history);
  }

  handleChange = (e) => {
    const { formValues } = this.state;
    let { validations } = this.state;
    const { name, value } = e.target;
    formValues[[name]] = value;

    if (name == 'password') {
      validations = validate(validations, name, value, formValues['password_confirmation']);
    } else if (name == 'password_confirmation') {
      validations = validate(validations, name, value, formValues['password']);
    } else {
      validations = validate(validations, name, value, formValues.password);
    }
    const {
      match: {
        params: { token },
      },
    } = this.props;
    formValues['token'] = token;
    this.setState({ formValues, validations });
  };

  checkValidation = () => {
    const { validations } = this.state;
    const check = checkValid(validations);
    if (check) {
      return true;
    }
    return false;
  };

  componentWillReceiveProps(props) {

  }

  componentDidMount() {
    if (this.props.user) {
      if (this.props.user) {
        this.props.history.push('/agency/dashboard');
        // toast.success('user login successfully.');
      }
    }
  }


  render() {
    const { formValues: { password, confirm_password }, validations: { passwordV, password_confirmationV } } = this.state;
    return (
      <div className="login-outer">
        <Container>
          <div className="login">
            <div className="login-left">
              <Link to={'/'}> <img src={Logo} alt="Kids Upfront Logo" /></Link>
              <div className="login-left-bottom"></div>
            </div>

            <div className="login-right">
              <h2>Set Password</h2>
              <Form>
                <FormGroup>
                  <DefaultInput
                    name="password"
                    type="password"
                    onChange={this.handleChange}
                    value={password}
                    validation={passwordV}
                    placeholder="Password"
                  />
                </FormGroup>

                <FormGroup>
                  <DefaultInput
                    name="confirm_password"
                    type="password"
                    onChange={this.handleChange}
                    value={confirm_password}
                    validation={password_confirmationV}
                    placeholder="Confirm Password"
                  />
                </FormGroup>

                <DefaultButton
                  name="Submit"
                  color="primary"
                  onClick={this.submitResetPassword}
                  block="true"
                  disabled={this.checkValidation()}
                />
              </Form>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}


export default withRouter(ResetPassword);
