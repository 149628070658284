import React from 'react';

class Footer extends React.Component {
  state = {
    isLoader: false
  }

  render() {
    return (
     <footer>
         <h1>FOOTER User</h1>
     </footer>
    );
  }
}

export default (Footer);
