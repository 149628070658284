/*eslint eqeqeq: "off"*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

export default function (ComposedComponent) {

    class RequireAuth extends Component {
        constructor(props) {
            super(props);
			if(!props.user &&  !localStorage.getItem('token')) {
               props.history.push('/');
            }
        }
       
        componentWillUpdate(nextProps) {
             if (!nextProps.user && !localStorage.getItem('token')) {
                nextProps.history.push('/');
            }
        }

        componentDidMount(prevProps, prevState, snapshot) {
            const user = this.props && this.props.user;
        }

        render() {
                return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return { user: state.authReducer.user };
    }

    return connect(mapStateToProps)(RequireAuth);
}
