/*eslint eqeqeq: "off"*/
import React from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class Landing extends React.Component {

  render() {
    return (
      <div>HomePage</div>
    );
  }
}

const mapStateToProps = (state) => ({
  //venue_categories: state.simpleReducer.get_categories,
});

export default withRouter(connect(mapStateToProps)(Landing));
