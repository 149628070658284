/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState } from 'react';
import { Button, Popover, PopoverHeader, PopoverBody,Row,Col } from 'reactstrap';
import { Icon } from '../../../UI/atoms/Icon';
import moment from 'moment';
const Agency = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);
  
const {status, index, popupData} = props
  return (
    <div>

	{status}
	    {(status == 'Change Requested'  || status == 'Change Allocated' )  &&
        <span class='info-span' id= {"Popover1" + index} onMouseOver={toggle} onMouseOut={toggle}>
		<Icon name="info" size= 'sm'></Icon>
	    </span> 
      }
  
	
      {(status == 'Change Requested'  || status == 'Change Allocated' )  && 
	  <Popover placement="right" isOpen={popoverOpen} target={"Popover1" + index} >
        <PopoverHeader></PopoverHeader>
        <PopoverBody>
            <div className="pop-content">
                <h6>Change Request</h6>
                
                    <p>Date Requested: {popupData.changeReqDateArray ? ((popupData.changeReqDateArray.length == 2) ? moment(popupData.changeReqDateArray[1]).format('lll') : (moment(popupData.changeReqDateArray[0]).format('lll'))): ''}</p>
               
                
                    <Row>
                        <Col xl="6" sm="12">
                            <p>Adult : {popupData.reqAdultTickets}</p>
                        </Col>
                        <Col xl="6" sm="12">
                            <p>Children : {popupData.reqChildrenTickets}</p>
                        </Col>
                    </Row>
                    <p>Total : {popupData.reqTotalTickets}</p>
                
            </div>

            <div className="pop-content">
                <h6>Previous Request</h6>
                
                    <p>Date Requested : {(popupData.changeReqDateArray && popupData.changeReqDateArray.length == 2) ? moment(popupData.changeReqDateArray[0]).format('lll'): popupData.createdon}</p>
               
               
                    <Row>
                        <Col xl="6" sm="12">
                            <p>Adult :  {popupData.actualAdultTickets}</p>
                        </Col>
                        <Col xl="6" sm="12">
                            <p>Children :  {popupData.actualChildrenTickets}</p>
                        </Col>
                    </Row>
                    <p>Total : {popupData.actualTotalTickets}</p>
               
            </div>
        </PopoverBody>
      </Popover>
	  }
    </div>
  );
}
// onMouseOver={toggle} onMouseOut={toggle}
export default Agency;
