/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllEvents, getAllEventsSorted, getEventCategories, generateTicketRequest } from "../../../../actions/EventActions";
import 'react-owl-carousel2/src/owl.carousel.css'; //Allows for server-side rendering.
import moment from "moment";
import { Container, Row, Col } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import EventCalendar from './eventCalendar';
import FeaturedEvents from './featuredEvents';
import EventsCategories from './eventCategories';
import DefaultButton from './../../../UI/atoms/DefaultButton';
import Popover from './popover';
import GenerateTicketRequestModalLimited from '../EventsManagement/GenerateTicketRequestModalLimited';
import { Icon } from './../../../UI/atoms/Icon';
import SearchInput from '../../../UI/atoms/searchBox'
import randomImages from '../../../../assets/randomImages'
import { get } from 'lodash'

class EventsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        isVisible: false,
        data: {}
      },
      selectedEventId: '',
      selectedEventName: '',
      resetFilter: false,
      date: '',
      allEvents: null,
      allEventsList: {},
      getEventCategories: [],
      allCatgoriesChecked: [],
      allElements: {
        // city: {
        //   operator: '',
        //   filter: 'evtech_city',
        //   eq: 'eq',
        //   id: localStorage.getItem('eventCity')
        // },
        _owningbusinessunit_value: {
          operator: '',
          filter: '_owningbusinessunit_value',
          eq: 'eq',
          id: this.props.agency_account_detail?.owningbusinessunit?.businessunitid
        },
        displayonweb: {
          operator: 'and',
          filter: 'evtech_displayonweb',
          eq: 'eq',
          id: true
        }
      },
      images: [
        { name: "Event 1", url: "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 2", url: "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 3", url: "https://images.pexels.com/photos/1047442/pexels-photo-1047442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 4", url: "https://images.pexels.com/photos/1684187/pexels-photo-1684187.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 5", url: "https://images.pexels.com/photos/1449791/pexels-photo-1449791.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 6", url: "https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 7", url: "https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 8", url: "https://images.pexels.com/photos/2774556/pexels-photo-2774556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 9", url: "https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 10", url: "https://images.pexels.com/photos/2399097/pexels-photo-2399097.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 11", url: "https://images.pexels.com/photos/3023317/pexels-photo-3023317.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 12", url: "https://images.pexels.com/photos/110472/pexels-photo-110472.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 1", url: "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 2", url: "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 3", url: "https://images.pexels.com/photos/1047442/pexels-photo-1047442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 4", url: "https://images.pexels.com/photos/1684187/pexels-photo-1684187.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 5", url: "https://images.pexels.com/photos/1449791/pexels-photo-1449791.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 6", url: "https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 7", url: "https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 8", url: "https://images.pexels.com/photos/2774556/pexels-photo-2774556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 9", url: "https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 10", url: "https://images.pexels.com/photos/2399097/pexels-photo-2399097.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 11", url: "https://images.pexels.com/photos/3023317/pexels-photo-3023317.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 12", url: "https://images.pexels.com/photos/110472/pexels-photo-110472.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 1", url: "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 2", url: "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 3", url: "https://images.pexels.com/photos/1047442/pexels-photo-1047442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 4", url: "https://images.pexels.com/photos/1684187/pexels-photo-1684187.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 5", url: "https://images.pexels.com/photos/1449791/pexels-photo-1449791.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 6", url: "https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 7", url: "https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 8", url: "https://images.pexels.com/photos/2774556/pexels-photo-2774556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 9", url: "https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 10", url: "https://images.pexels.com/photos/2399097/pexels-photo-2399097.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 11", url: "https://images.pexels.com/photos/3023317/pexels-photo-3023317.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 12", url: "https://images.pexels.com/photos/110472/pexels-photo-110472.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" }
      ]
    }
  }

  resetFilter = () => {
    let { allElements } = this.state;
    allElements = {
      // city: {
      //   operator: '',
      //   filter: 'evtech_city',
      //   eq: 'eq',
      //   id: localStorage.getItem('eventCity')
      // },
      _owningbusinessunit_value: {
        operator: '',
        filter: '_owningbusinessunit_value',
        eq: 'eq',
        id: this.props.agency_account_detail?.owningbusinessunit?.businessunitid
      },
      displayonweb: {
        operator: 'and',
        filter: 'evtech_displayonweb',
        eq: 'eq',
        id: true,


      }
    }


    this.setState({ allElements, resetFilter: true, allCatgoriesChecked: [], date: '' }, () => { this.callFilter(allElements) });
  }


  callFilter = (elem) => {
    // this.props.getAllEventsSorted({ ...elem });
    let { allCatgoriesChecked } = this.state;
    this.props.getAllEventsSorted(elem, allCatgoriesChecked);
  }

  onChange = (date) => {
    let { allElements } = this.state;
    allElements.date = {
      operator: 'and',
      filter: '( evtech_startdate',
      eq: 'ge',
      id: moment(date).format('YYYY-MM-DD'),

      operator2: 'and',
      filter2: 'evtech_startdate',
      eq2: 'lt',
      id2: moment(date).add(1, 'days').format('YYYY-MM-DD'),

      operator3: 'or',
      filter3: '(evtech_startdate',
      eq3: 'lt',
      id3: moment(date).format('YYYY-MM-DD'),

      operator4: 'and',
      filter4: 'evtech_enddate',
      eq4: 'gt',
      id4: moment(date).format('YYYY-MM-DD') + '))'
    };
    //  allElements.date.id = moment(date).format("YYYY-MM-DD");
    this.setState({ resetFilter: false })
    this.setState({ date, allElements }, () => { this.callFilter(allElements) });
  }

  // sortByCategory = (e, event) => {
  //   let element = {
  //     filter: 'evtech_eventcategory',
  //     eq: 'eq',
  //     id: event.code
  //   }

  //   let { allElements, allCatgoriesChecked } = this.state;
  //   allCatgoriesChecked[event.name] = e.target.checked;

  //   if (e.target.checked == false) {
  //     delete allElements[event.name]
  //   } else {

  //     if (Object.keys(allElements).length < 2) {
  //       element.operator = 'and';
  //     }

  //     if (Object.keys(allElements).length >= 2) {
  //       element.operator = 'or';
  //     }

  //     allElements[event.name] = element;
  //   }

  //   this.setState({ allElements, allCatgoriesChecked }, () => { this.callFilter(allElements) });
  // }

  componentWillMount() {
    this.props.getEventCategoriesFn();
    let { allElements } = this.state;
    this.callFilter(allElements);
  }

  componentDidUpdate(previousProps) {

    const { get_event_categories } = this.props
    const { get_event_categories_State, allEventsList } = this.state

    if (get_event_categories !== get_event_categories_State) {
      let getEventCategoriesList = []
      get_event_categories.length > 0 && get_event_categories.map((event) => {
        getEventCategoriesList.push({
          value: event.code,
          label: event.name
        })
        return true
      })

      this.setState({
        get_event_categories_State: get_event_categories,
        getEventCategories: getEventCategoriesList
      })
    }
    if (allEventsList !== this.props.get_sorted_events) {
      if (Array.isArray(this.props.get_sorted_events) && this.props.get_sorted_events.length >= 0) {
        this.setState({
          allEvents: this.props.get_sorted_events,
          allEventsList: this.props.get_sorted_events

        })
      }
      // }
    }

    // if (previousProps !== this.props) {
    //   if (Array.isArray(this.props.get_all_events) && this.props.get_all_events.length > 0) {
    //     this.setState({ allEvents: this.props.get_all_events })
    //   }
    //   if (Array.isArray(this.props.get_sorted_events) && this.props.get_sorted_events.length > 0) {
    //     this.setState({ allEvents: this.props.get_sorted_events })
    //   }
    // }
  }

  generateTicketRequest = (selectedEventId, selectedEventName) => {
    this.setState({ selectedEventId: selectedEventId, selectedEventName: selectedEventName, modal: { ...this.state.modal, isVisible: !this.state.modal.isVisible, data: {} } })
  };


  submitGenerateRequest = ({ comment, adultTickets, childrenTickets }) => {
    let { selectedEventId, selectedEventName } = this.state;
    const eventId = selectedEventId ? selectedEventId : null;
    const agencyCode = this.props.agencyCode;
    const agencyId = this.props.guid;
    const requestedBy = this.props.dynamic && this.props.dynamic.name;

    const totalTickets = parseInt(adultTickets) + parseInt(childrenTickets);
    const ticketIds = {};
    const bodyParams = {
      "evtech_chaperonename": requestedBy,
      "cr676_event_name": selectedEventName,
      "cr676_agency_code": agencyCode,
      "evtech_totalticketvalue_base": 100,
      "evtech_totalticketvalue": 8,
      "evtech_comments": comment,
      "evtech_numberofchildren": childrenTickets,
      "evtech_numberofchaperones": adultTickets,
      "evtech_totaltickets": totalTickets,
      "cr676_client_request_id": "",
      "evtech_eventid@odata.bind": `evtech_events(${eventId})`,
      "evtech_agencyid@odata.bind": `accounts(${agencyId})`,
    }
    this.props.generateTicketRequest(bodyParams, { ticketIds, agencyCode, eventId });
    this.setState({ modal: { ...this.state.modal, isVisible: !this.state.modal.isVisible } })
  }


  allEvents = () => {
    const { allEvents } = this.state;
    let values = [];
    const userPermission = this.props.user ? this.props.user.data && this.props.user.data.userPermission : null;
    if (Array.isArray(allEvents) && allEvents.length > 0) {
      allEvents.map((event, index) => {
        const eventImage = event.cr676_image ? `data:image/jpeg;base64,${event.cr676_image}` : randomImages[event.evtech_eventcategory] ? randomImages[event.evtech_eventcategory][index % randomImages[event.evtech_eventcategory].length] : randomImages.random[index % randomImages.random.length];

        values.push(
          <Col className="col-xxl-4" key={index} xs="12" sm="12" md="12" lg="12" xl="6">
            <div className="event">
              <div className="image-outer"><img src={eventImage} alt={event.evtech_name} /></div>
              <div className="event-des">
                <h5 title={event.evtech_name}>{event.evtech_name}</h5>
                {/* <p><Icon name="calendar" /> From {event.evtech_startdate ? moment(event.evtech_startdate).format("DD-MM-YY") : ''} To  &nbsp;
                {event.evtech_enddate ? moment(event.evtech_enddate).format("DD-MM-YY") : ''}
                </p> */}
                <p><Icon name="calendar" />  {event.evtech_startdate ? moment.utc(event.evtech_startdate).format("MMM DD, YYYY") : ''} {event.evtech_enddate ? "- " + moment.utc(event.evtech_enddate).format("MMM DD, YYYY") : ''}</p>
                <p><Icon name="clock-o" /> {event.evtech_startdate ? moment.utc(event.evtech_startdate).format("hh:mm A") : ''} {event.evtech_enddate ? "- " + moment.utc(event.evtech_enddate).format("hh:mm A") : ''}</p>
                <p><Icon name="map-marker" /> {event.evtech_venueid ? event.evtech_venueid.evtech_name : ''}</p>

                {/* <p>{event.evtech_description}</p> */}
              </div>
              {(userPermission) ?
                <Link to={{ pathname: `/agency/event-detail/${event.evtech_eventid}`, state: { eventImage } }} key={index}>
                  <DefaultButton color="link" name="Read More" />
                </Link>
                :
                <Link to={{ pathname: `/agency/event-detail/${event.evtech_eventid}`, state: { eventImage } }} key={index}>
                  <DefaultButton color="link" name="Read More" onClick={() => { this.generateTicketRequest(event.evtech_eventid, event.evtech_name) }} />
                </Link>
                // <DefaultButton color="link" name="Submit Request" onClick={() => { this.generateTicketRequest(event.evtech_eventid, event.evtech_name) }} />
              }
            </div>
          </Col>
        )
      })
      return values;
    }
  }

  sortByCategory = (allCatgories) => {
    let { allElements } = this.state;
    this.setState({ resetFilter: false })
    this.setState({ allCatgoriesChecked: allCatgories, allElements }, () => {
      this.callFilter(allElements, allCatgories)
    });
  }

  getSerach = (value) => {
    this.setState({ resetFilter: false })
    let { allElements } = this.state;

    allElements['search'] = {
      filter: 'contains',
      eq: 'eq',
      id: value,
      operator: "and"
    }
    this.callFilter(allElements)

  }

  render() {
    let { date, allCatgoriesChecked, getEventCategories, allEvents, resetFilter } = this.state;
    const { get_event_categories } = this.props;
    return (
      <div className="dashboard">
        <Container fluid={true}>
          {/*<Popover/>*/}


          <div className="dashboard-content">
            <div className="dashboard-content-left">

              <div className="dashboard-heading">
                <span className="section-icon"><svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Calendar"><path d="m12 47h4.001v3h-4.001z" fill="#689729" /><path d="m49 38a11 11 0 1 0 11 11 11.013 11.013 0 0 0 -11-11zm7.192 8.222-8.485 8.485a1 1 0 0 1 -1.414 0l-4.242-4.243a1 1 0 0 1 1.414-1.414l3.535 3.536 7.778-7.778a1 1 0 0 1 1.414 1.414z" fill="#689729" /><path d="m24 29h4.001v3h-4.001z" fill="#689729" /><path d="m36 29h4v3h-4z" fill="#689729" /><path d="m12 38h4.001v3h-4.001z" fill="#689729" /><path d="m24 38h4.001v3h-4.001z" fill="#689729" /><path d="m12 29h4.001v3h-4.001z" fill="#689729" /><path d="m16 14a2.006 2.006 0 0 0 2-2v-6a2 2 0 0 0 -4 0v6a2.006 2.006 0 0 0 2 2z" fill="#689729" /><path d="m32 14a2.006 2.006 0 0 0 2-2v-6a2 2 0 0 0 -4 0v6a2.006 2.006 0 0 0 2 2z" fill="#689729" /><path d="m48 14a2.006 2.006 0 0 0 2-2v-6a2 2 0 0 0 -4 0v6a2.006 2.006 0 0 0 2 2z" fill="#689729" /><path d="m48 29h4v3h-4z" fill="#689729" /><path d="m57 10h-5v2a4 4 0 0 1 -8 0v-2h-8v2a4 4 0 0 1 -8 0v-2h-8v2a4 4 0 0 1 -8 0v-2h-5a3.009 3.009 0 0 0 -3 3v7h56v-7a3.009 3.009 0 0 0 -3-3z" fill="#689729" /><path d="m24 47h4.001v3h-4.001z" fill="#689729" /><path d="m4 22v31a3.009 3.009 0 0 0 3 3h31.063a12.984 12.984 0 1 1 21.937-13.9v-20.1zm14 28a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm0-9a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm0-9a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm12 18a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm0-9a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm0-9a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm10 6h-4v3a1 1 0 0 1 0 2 2 2 0 0 1 -2-2v-3a2 2 0 0 1 2-2h4a1 1 0 0 1 0 2zm2-6a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2zm12 0a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2-2v-3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2z" fill="#689729" /></g></svg></span>
                <h4>EVENTS LISTING</h4>
              </div>
              <div>
                <DefaultButton color="link" onClick={this.resetFilter} className="no-padding" name="Clear Filter" />
              </div>

              <div className="calender-outer">
                <h6>Looks Event For</h6>
                <EventCalendar date={date} onChange={this.onChange} />
              </div>

              <div className="events-category">
                <h6>Search</h6>
                <FormGroup>
                  <div>
                    <SearchInput
                      resetFilter={resetFilter}
                      getSerach={this.getSerach} />
                  </div>
                </FormGroup>

                <h6>Category</h6>
                <FormGroup>
                  <div>
                    <EventsCategories
                      allCatgoriesChecked={allCatgoriesChecked}
                      get_event_categories={getEventCategories}
                      sortByCategory={this.sortByCategory} />

                    {/* <EventsCategories
                      get_event_categories={get_event_categories}
                      sortByCategory={this.sortByCategory} /> */}
                  </div>
                </FormGroup>
              </div>

            </div>
            <div className="dashboard-content-right">

              <div className="slider-outer">
                <FeaturedEvents images={this.state.images} generateTicketRequestFn={this.generateTicketRequest} />
              </div>

              <div className="slider-outer all-events">
                <h3>All Events</h3>
                {allEvents && allEvents.length > 0 ? (
                  <Row>
                    {this.allEvents()}
                  </Row>) : "No Events Found"}
              </div>
            </div>
          </div>
        </Container>
        {this.state.modal.isVisible ?
          <GenerateTicketRequestModalLimited
            data={''}
            onSubmitGenerateRequest={this.submitGenerateRequest}
            onCancel={this.generateTicketRequest} />
          : ""}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dynamic: state.authReducer && state.authReducer.dynamic_user_detail,
    agencyGuid: state.authReducer.user && state.authReducer.user.data && state.authReducer.user.data.guid,
    agencyLocation: state.authReducer.user && state.authReducer.user.data && state.authReducer.user.data.location,
    agencyCode: state.authReducer.user && state.authReducer.user.data && state.authReducer.user.data.agency_code && state.authReducer.user.data.agency_code,
    guid: state.authReducer.user && state.authReducer.user.data && state.authReducer.user.data.guid,
    get_all_events: state.eventReducer.get_all_events.value,
    get_sorted_events: state.eventReducer.get_sorted_event.value,
    get_event_categories: state.eventReducer.get_event_categories,
    generateTicketRequest: state.eventReducer.generateTicketRequest,
    user: state.authReducer.user,
    agency_account_detail: state?.authReducer?.agency_account_detail?.value?.[0]
  }
}


const mapDispatch = (dispatch) => ({
  getAllEvents: () => dispatch(getAllEvents()),
  // getAllEventsSorted: ({ ...rest }) => dispatch(getAllEventsSorted({ ...rest })),
  getAllEventsSorted: (rest, allCatgoriesChecked) => dispatch(getAllEventsSorted(rest, allCatgoriesChecked)),
  getEventCategoriesFn: user => dispatch(getEventCategories(user)),
  generateTicketRequest: (body, opts) => dispatch(generateTicketRequest(body, opts)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(EventsList));
