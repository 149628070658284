import { callApi, callDynamicApi } from "../utils/apiCaller";
import { toast } from "react-toastify";
import moment from 'moment';
import { DYNAMICS_API_URL } from "../utils/constants";
export const GET_EVENT_CATEGORIES = 'GET_EVENT_CATEGORIES';
export const GET_EVENT_FEATURED_CATEGORIES = 'GET_EVENT_FEATURED_CATEGORIES';
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_EVENTS_SORTED = 'GET_EVENTS_SORTED';
export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';
export const GET_EVENT_CLIENT_REQUESTS = 'GET_EVENT_CLIENT_REQUESTS';
export const GET_EVENT_TICKET_ALLOCATION_REQUESTS = 'GET_EVENT_TICKET_ALLOCATION_REQUESTS';
export const GET_EVENT_AGENCY_REQUESTS = 'GET_EVENT_AGENCY_REQUESTS';
export const SAVE_TICKET_ALLOACATED = 'SAVE_TICKET_ALLOACATED';
export const REQUEST_TICKET_ALLOACATED = 'REQUEST_TICKET_ALLOACATED';
export const SUBMIT_CHANGE_REQUEST = 'SUBMIT_CHANGE_REQUEST';
export const UPDATE_BATCH_AGENCY_REQUESTS = 'UPDATE_BATCH_AGENCY_REQUESTS';
export const EDIT_CHANGE_REQUEST = 'EDIT_CHANGE_REQUEST';
export const INSERT_HEADER_AS_KEY = 'INSERT_HEADER_AS_KEY';
export const GET_EXPERIENCE_AGENCY_REQUESTS = 'GET_EXPERIENCE_AGENCY_REQUESTS';
export const GET_EXPERIENCE_CLIENT_REQUESTS = 'GET_EXPERIENCE_CLIENT_REQUESTS';
export const GET_EXPERIENCE_TICKET_REQUESTS = 'GET_EXPERIENCE_TICKET_REQUESTS';
export const ALLOCATE_TICKET = 'ALLOCATE_TICKET';
export const TOTAL_RECEIVED_ALLOCATED_TICKET = 'TOTAL_RECEIVED_ALLOCATED_TICKET';
export const MODIFY_TICKET_ALLOCATION = 'MODIFY_TICKET_ALLOCATION';
export const UPDATE_BATCH_SHERED_REQUESTS = "UPDATE_BATCH_SHERED_REQUESTS";
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_ON_CHANGE_STATUS = "SEND_EMAIL_ON_CHANGE_STATUS";
export const TOTAL_AGENCY_TICKET = "TOTAL_AGENCY_TICKET";
export const SAVE_CHANGE_AGENCY_REQUEST = "SAVE_CHANGE_AGENCY_REQUEST";
export const GET_CHANGE_AGENCY_REQUEST = "GET_CHANGE_AGENCY_REQUEST";
export const DELETE_CHANGE_AGENCY_REQUEST = "DELETE_CHANGE_AGENCY_REQUEST";
export const DELETE_AGENCY_REQUESTS = "DELETE_AGENCY_REQUESTS";
export const GET_REQUEST_TICKET = "GET_REQUEST_TICKET";
export const GET_CLIENT_HOVER_DATA = "GET_CLIENT_HOVER_DATA";


export const getEventCategories_ = (payload) => {
    return {
        type: GET_EVENT_CATEGORIES,
        payload,
    }
}

export const getEventCategories = (data) => {
    return async (dispatch) => {
        return callApi('list-categories/', 'GET', data)
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(getEventCategories_(response.data))
                }
            })
    }
}

export const getFeaturedCategories_ = (payload) => {
    return {
        type: GET_EVENT_FEATURED_CATEGORIES,
        payload,
    }
}
// evtech_events?$filter=evtech_displayonweb eq true
// 'evtech_events?$filter=${filter} ${eq} ${isTrue}/
export const getFeaturedCategories = (businessUnitId) => {
    return (dispatch) => {
        let qa = window.unescape(`evtech_events?$filter=evtech_displayonweb eq true and cr676_featured eq true and _owningbusinessunit_value eq ${businessUnitId} &$expand=evtech_venueid($select=evtech_name)&$orderby=evtech_startdate`)

        //let qa = window.unescape("evtech_events?$filter=evtech_displayonweb eq true and cr676_featured eq true and evtech_city eq '"+evtech_city+"'and cr676_event_turn_off ne 476940001 &$expand=evtech_venueid($select=evtech_name)")
        callDynamicApi(qa, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(getFeaturedCategories_(response.data))
                }
            })
    }
}

export const getClientHoverData = (eventId, email) => {
    let baseUrl = `${DYNAMICS_API_URL}`;
    let body = `--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=emailaddress eq '${email}' and _evtech_eventid_value eq ${eventId}&$apply=aggregate(evtech_totaltickets with sum as total_requested,evtech_numberofchaperones with sum as total_adult_requested,evtech_numberofchildren with sum as total_number_of_child) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=emailaddress eq '${email}' &$apply=aggregate(evtech_totaltickets with sum as total_requested,evtech_numberofchaperones with sum as total_adult_requested,evtech_numberofchildren with sum as total_number_of_child) HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B
Content-Type: application/http
Content-Transfer-Encoding:binary

GET ${baseUrl}evtech_ticketrequests?$filter=emailaddress eq '${email}' &$count=true &$select=evtech_ticketrequestid HTTP/1.1
Prefer: odata.maxpagesize=1
Accept: application/json

--batch_B--`;

    return (dispatch) => {
        callDynamicApi('$batch', 'POST', body, false, 'multipart/mixed;boundary=batch_B')
            .then((response) => {
                if (response && response.status !== 400 && response.status !== 500) {
                    const body = response.data;
                    const values = getValuesFromBatchResponse(body);
                    const counts = getCountsFromBatchResponse(body);
                    const data = values.concat(counts);
                    return dispatch(getClientHoverData_(data))
                    // const values = getValuesFromBatchResponse(body);
                    //return dispatch(getAgencyFilterData_(values))
                }
            })
    }

}

export const getClientHoverData_ = (payload) => {
    return {
        type: GET_CLIENT_HOVER_DATA,
        payload,
    }
}

export const getEventAgencyRequest_ = (payload) => {
    return {
        type: GET_EVENT_AGENCY_REQUESTS,
        payload,
    }
}
// evtech_events?$filter=evtech_displayonweb eq true
// 'evtech_events?$filter=${filter} ${eq} ${isTrue}/
export const getEventAgencyRequest = (agency_guid, eventId) => {
    return (dispatch) => {
        let url = window.unescape('evtech_ticketrequests?$filter=_evtech_agencyid_value eq ' + agency_guid + ' and _evtech_eventid_value eq ' + eventId + ' and cr676_delete_status ne true &$expand=evtech_agencyid($select=name)');

        callDynamicApi(url, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(getEventAgencyRequest_(response.data))
                }
            })
    }
}

export const getExperienceAgencyRequest_ = (payload) => {
    return {
        type: GET_EXPERIENCE_AGENCY_REQUESTS,
        payload
    }
}

export const getExperienceAgencyRequest = (agency_guid) => {
    return (dispatch) => {
        let url = window.unescape('evtech_ticketrequests?$filter=_evtech_agencyid_value eq ' + agency_guid + ' and cr676_delete_status ne true &$expand=evtech_agencyid($select=name)');
        callDynamicApi(url, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(getExperienceAgencyRequest_(response.data))
                }
            })
    }
}

export const getExperienceClientRequest_ = (payload) => {
    return {
        type: GET_EXPERIENCE_CLIENT_REQUESTS,
        payload
    }
}

export const getExperienceClientRequest = (agencyCode) => {
    return (dispatch) => {
        let url = window.unescape(`evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}'  and _evtech_agencyid_value eq null`);
        callDynamicApi(url, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(getExperienceClientRequest_(response.data))
                }
            })
    }
}

export const getExperienceTicketRequest_ = (payload) => {
    return {
        type: GET_EXPERIENCE_TICKET_REQUESTS,
        payload
    }
}

export const getExperienceTicketRequest = (agencyCode) => {
    return (dispatch) => {

        let url = window.unescape(`evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and (evtech_requeststatus eq 476940000 or evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004)`);
        callDynamicApi(url, 'GET')
            .then((response) => {
                if (response && response.status !== 400 && response.status !== 500) {
                    return dispatch(getExperienceTicketRequest_(response.data))
                }
            })
    }
}



/*export const deleteRequestAgencyRequest = (ticketId) => {
       return (dispatch) => {
        let url = window.unescape(`evtech_ticketrequests(${ticketId})`);
        callDynamicApi(url, 'DELETE')
            .then((response) => {
                if (response && response.status !== 400 && response.status !== 500) {
                    return dispatch(deleteRequestAgencyRequest_(response.data))
                }
            })
    }
}*/


export const getEventAgencyRequestData = (dispatch, agency_guid, eventId) => {
    let url = window.unescape('evtech_ticketrequests?$filter=_evtech_agencyid_value eq ' + agency_guid + ' and _evtech_eventid_value eq ' + eventId + ' and cr676_delete_status ne true &$expand=evtech_agencyid($select=name)');

    callDynamicApi(url, 'GET')
        .then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(getEventAgencyRequest_(response.data))
            }
        })
}


export const deleteRequestAgencyRequest_ = (payload) => {
    return {
        type: DELETE_AGENCY_REQUESTS,
        payload
    }
}

export const deleteRequestAgencyRequest = (dispatch, ticketId, agencyCode, agency_guid, eventId) => {

    let url = window.unescape(`evtech_ticketrequests(${ticketId})`);
    callDynamicApi(url, 'DELETE')
        .then((response) => {
            if (response && response.status !== 400 && response.status !== 500) {
                getEventAgencyRequestData(dispatch, agency_guid, eventId)
                getRequestTickets(dispatch, agencyCode, eventId);
                toast.success('Agency request deleted successfully');
                return dispatch(deleteRequestAgencyRequest_(response.data))
            }
        })

}

export const updateBatchAgencyRequest_ = (payload) => {
    return {
        type: UPDATE_BATCH_AGENCY_REQUESTS,
        payload,
    }
}

export const updateBatchAgencyRequest = (clientRequestid, ticketRequestId, agencyCode, agencyGuid, eventId) => {
    let clientReq = '';
    let baseUrl = `${DYNAMICS_API_URL}`;
    for (var i = 0; i < clientRequestid.length; i++) {
        clientReq += "--changeset_BBB456\nContent-Type: application/http\nContent-Transfer-Encoding:binary\nContent-ID: " + (parseInt(i) + 1) + "\n\nPATCH " + baseUrl + "evtech_ticketrequests(" + clientRequestid[i] + ") HTTP/1.1\nContent-Type: application/json;type=entry\nPrefer: return=representation\n\n{\n\"evtech_requeststatus\":807710002 \n} \n\n";
    }
    var data = "--batch_B\nContent-Type: multipart/mixed;boundary=changeset_BBB456\n\n-" + clientReq + "--changeset_BBB456--\n--batch_B--\n";
    return (dispatch) => {
        callDynamicApi('$batch', 'POST', data, false, 'multipart/mixed;boundary=batch_B')
            .then((response) => {
                if (response && response.status !== 400 && response.status !== 500) {
                    deleteRequestAgencyRequest(dispatch, ticketRequestId, agencyCode, agencyGuid, eventId)
                    return dispatch(updateBatchAgencyRequest_(response))
                }
            })
    }
}

export const updateBatchSharedRequest_ = (payload) => {
    return {
        type: UPDATE_BATCH_SHERED_REQUESTS,
        payload,
    }
}

export const updateBatchSharedRequest = (sharedTicketData, subject, body, emailData) => {
    let clientReq = '';
    let baseUrl = `${DYNAMICS_API_URL}`;
    let content = [];
    for (var i = 0; i < sharedTicketData.length; i++) {
        clientReq += "--changeset_BBB456\nContent-Type: application/http\nContent-Transfer-Encoding:binary\nContent-ID: " + (parseInt(i) + 1) + "\n\nPATCH " + baseUrl + "evtech_ticketrequests(" + sharedTicketData[i].ticketRequestId + ") HTTP/1.1\nContent-Type: application/json;type=entry\nPrefer: return=representation\n\n{\n\t\"evtech_requeststatus\" : 476940004,\n\t\"evtech_ticketdistribution\" : 807710002,\n\t\"evtech_distributiondate\" :\"2020-11-01T00:00:00Z\"\n} \n\n";
        content.push({
            "subject": subject,
            "email": sharedTicketData[i].email,
            "message": body,
            "confirmed_tickets": sharedTicketData[i].totalTicketsTbs,
            "distribution": emailData?.evtech_ticketdistribution,
            "agency": emailData?.agencyName,
            "event": emailData?.eventName,
            "start_date": emailData?.startDate,
            "event_category": emailData?.category,
            "age_range": emailData?.ageRange,
            "venue": emailData?.address,
            "business_unit": emailData?.business_unit,
            "evtech_description": emailData?.evtech_description || null,
            "ticket_distribution": sharedTicketData[i]?.evtech_ticketdistribution || null
        })
    }
    var data = "--batch_B\nContent-Type: multipart/mixed;boundary=changeset_BBB456\n\n-" + clientReq + "--changeset_BBB456--\n--batch_B--\n";
    return (dispatch) => {
        callDynamicApi('$batch', 'POST', data, false, 'multipart/mixed;boundary=batch_B')
            .then((response) => {
                if (response && response.status !== 400 && response.status !== 500) {
                    sendEmailToShared(content, dispatch);
                    //return dispatch(updateBatchSharedRequest_(response))
                }
            })
    }
}

const sendEmailToShared = (content, dispatch) => {
    callApi('agency-portal-to-client-ticket-claim/', 'POST', { content }).then((res) => {
        if (res.code == 200) {
            return dispatch(updateBatchSharedRequest_(res))
        }
    });
};

export const sendEmailOnChangeStatus_ = (payload) => {
    return {
        type: SEND_EMAIL_ON_CHANGE_STATUS,
        payload,
    }
}

export const sendEmailOnChangeStatus = (content) => {
    return (dispatch) => {
        callApi('email-ticket-status/', 'POST', { content })
            .then((response) => {
                if (response.code === 200) {
                    return dispatch(sendEmailOnChangeStatus_(response))
                }
            })
    }
}

export const sendEmailOnRejectClientTickets = (content) => {
    return (dispatch) => {
        callApi('email-reject/', 'POST', { content })
            .then((response) => {
                if (response.code === 200) {
                    return dispatch(sendEmailOnChangeStatus_(response))
                }
            })
    }
}

export const getAllEvents_ = (payload) => {
    return {
        type: GET_ALL_EVENTS,
        payload,
    }
}

export const getAllEvents = () => {
    return (dispatch) => {
        callDynamicApi('evtech_events?$top=&$orderby=evtech_startdate', 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(getAllEvents_(response.data))
                }
            })
    }
}

export const getAllEventsSorted_ = (payload) => {
    return {
        type: GET_EVENTS_SORTED,
        payload,
    }
}

export const getAllEventsSorted = (rest, allCatgories) => {
    let string = '';
    for (let [key, data] of Object.entries(rest)) {
        if (key === "city") {
            if (data.id !== "") {
                string += ` ${data.operator} ${data.filter} ${data.eq} '${data.id}'`;
            }
        }
        else if (key === "search") {
            if (data.id !== "") {
                string += ` ${data.operator} ${data.filter}(evtech_name,'${data.id}') `
            }
        }
        else {
            string += ` ${data.operator} ${data.filter} ${data.eq} ${data.id}`;
            if (data.operator2) {
                string += ` ${data.operator2} ${data.filter2} ${data.eq2} ${data.id2}`;
            }
            if (data.operator3) {
                string += ` ${data.operator3} ${data.filter3} ${data.eq3} ${data.id3}`;
            }
            if (data.operator4) {
                string += ` ${data.operator4} ${data.filter4} ${data.eq4} ${data.id4}`;
            }
        }
    }

    let categoryQuery = ''
    allCatgories && allCatgories.map((iteam, index) => {
        categoryQuery += ` ${index === 0 ? '' : 'or'} evtech_eventcategory eq ${iteam.value}`;
        return true
    })

    if (categoryQuery && categoryQuery !== '')
        string += ` and (${categoryQuery})`

    return (dispatch) => {
        // let qa = window.unescape(`evtech_events?$filter=${filter} ${eq} ${id}`)
        let qa = window.unescape(`evtech_events?$filter=${string} &$expand=evtech_venueid($select=evtech_name)&$orderby=evtech_startdate`);
        callDynamicApi(qa, 'GET').then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(getAllEventsSorted_(response.data));
            }
        });
    };
};

// export const getAllEventsSorted = 
// ({ ...rest }) => {

//     let string = '';
//     for (let [key, data] of Object.entries(rest)) {
//         string += ` ${data.operator} ${data.filter} ${data.eq} ${data.id} and evtech_displayonweb eq true and cr676_featured ne true`
//     }

//     return (dispatch) => {
//         let qa = window.unescape(`evtech_events?$filter=${string}`)
//         callDynamicApi(qa, 'GET')
//             .then((response) => {
//                 if (response && response.code !== 400 && response.code !== 500) {
//                     return dispatch(getAllEventsSorted_(response.data))
//                 }
//             })
//     }
// }

export const getEventDetails_ = (payload) => {
    return {
        type: GET_EVENT_DETAILS,
        payload
    }
}

export const getEventDetails = (eventId) => {

    return (dispatch) => {
        callDynamicApi(`evtech_events(${eventId})?$filter=%20%20_owningbusinessunit_value%20eq%20c21eabd2-2762-e811-a953-000d3af3e7d5%20and%20evtech_displayonweb%20eq%20true%20&$expand=evtech_venueid($select=evtech_name)&$orderby=evtech_startdate`, 'GET')
            .then((response) => {
                if (response && response.status !== 400 && response !== 500) {
                    return dispatch(getEventDetails_(response.data))
                }
            })
    }
}

export const getEventClientRequests_ = (payload) => {
    return {
        type: GET_EVENT_CLIENT_REQUESTS,
        payload
    }
}

export const getEventClientRequests = (agencyCode, eventId) => {
    return (dispatch) => {
        let qs = window.unescape(`/evtech_ticketrequests?$orderby=createdon desc &$filter=cr676_agency_code eq '${agencyCode}' and _evtech_eventid_value eq '${eventId}' and _evtech_agencyid_value eq null`)
        callDynamicApi(qs, 'GET')
            .then((response) => {
                if (response && response.status !== 400 && response !== 500) {
                    getRequestTickets(dispatch, agencyCode, eventId);
                    getTotalReceivedAndAllocated(dispatch, eventId, agencyCode);
                    return dispatch(getEventClientRequests_(response.data))
                }
            })
    }
}

export const onSaveTicketAllocated_ = (payload) => {
    return {
        type: SAVE_TICKET_ALLOACATED,
        payload
    }
}

export const onSaveTicketAllocated = (ticketReqID, agencyCode, eventId, body) => {
    return (dispatch) => {
        callDynamicApi(`evtech_ticketrequests(${ticketReqID})`, 'PATCH', body)
            .then((response) => {
                if (response && response.status !== 400 && response !== 500) {
                    return dispatch(onSaveTicketAllocated_(response.data))
                }
            }).then(() => {
                getEventClientRequests(agencyCode, eventId)(dispatch);
            }
            )
    }
}

export const changeRequestAgencyRequest_ = (payload) => {
    return {
        type: SUBMIT_CHANGE_REQUEST,
        payload
    }
}

export const changeRequestAgencyRequest = (ticketId, data, agencyCode, eventId) => {
    return (dispatch) => {
        callDynamicApi('evtech_ticketrequests(' + ticketId + ')', 'PATCH', data).then((response) => {
            if (response && response.status !== 400 && response.status !== 500) {
                getRequestTickets(dispatch, agencyCode, eventId);
                return dispatch(changeRequestAgencyRequest_(response));

            }
        });
    };
};

export const saveChangeAgencyRequest_ = (payload) => {
    return {
        type: SAVE_CHANGE_AGENCY_REQUEST,
        payload
    }
}

export const saveChangeAgencyRequest = (data) => {
    return (dispatch) => {
        callDynamicApi('evtech_changerequests', 'POST', data).then((response) => {
            if (response && response.status !== 400 && response.status !== 500) {
                return dispatch(changeRequestAgencyRequest_(response));

            }
        });
    };
};

export const getChangeAgencyRequest_ = (payload) => {
    return {
        type: GET_CHANGE_AGENCY_REQUEST,
        payload
    }
}


export const getChangeAgencyRequest = (ticketReqId) => {
    return async (dispatch) => {
        const qs = `evtech_changerequests?$filter=_evtech_ticketrequestid_value eq ${ticketReqId}`
        return callDynamicApi(qs, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response !== 500) {
                    if (response.data.value.length > 0) {
                        let changeRequestId = response.data.value[response.data.value.length - 1].evtech_changerequestid;
                        deleteChangeAgencyRequest(changeRequestId, dispatch);
                    }
                    return dispatch(getChangeAgencyRequest_(response.data));
                }
            })
    }
}

export const deleteChangeAgencyRequest_ = (payload) => {
    return {
        type: DELETE_CHANGE_AGENCY_REQUEST,
        payload
    }
}

export const deleteChangeAgencyRequest = (changeRequestId, dispatch) => {
    const qs = `evtech_changerequests(${changeRequestId})`
    return callDynamicApi(qs, 'DELETE')
        .then((response) => {
            if (response && response.status !== 400 && response !== 500) {
                return dispatch(deleteChangeAgencyRequest_(response.data))
            }
        })
}

export const modifyTicketAllocationRequest_ = (payload) => {
    return {
        type: MODIFY_TICKET_ALLOCATION,
        payload
    }
}

export const modifyTicketAllocationRequest = (ticketId, data, eventId, agencyCode) => {
    return (dispatch) => {
        callDynamicApi('evtech_ticketrequests(' + ticketId + ')', 'PATCH', data).then((response) => {
            if (response && response.status !== 400 && response.status !== 500) {
                getTotalReceivedAndAllocated(dispatch, eventId, agencyCode)
                return dispatch(modifyTicketAllocationRequest_(response));
            }
        });
    };
};

export const getTicketAllocationRequest_ = (payload) => {
    return {
        type: GET_EVENT_TICKET_ALLOCATION_REQUESTS,
        payload
    }
}

export const getTicketAllocationRequest = (agencyCode, eventId) => {
    return (dispatch) => {
        // let qs = window.unescape(`evtech_ticketrequests?$filter=_evtech_eventid_value eq '${eventId}' and _createdby_value eq ${agencyId} and evtech_requeststatus eq 807710002 and _cr676_agency_id_for_client_value eq null`)
        const qs = window.unescape(`evtech_ticketrequests?$orderby=createdon desc &$filter=cr676_agency_code eq '${agencyCode}' and _cr676_agency_id_for_client_value ne null and (evtech_requeststatus eq 476940000 or evtech_requeststatus eq 476940003 or evtech_requeststatus eq 476940004) and _evtech_eventid_value eq '${eventId}'`)

        callDynamicApi(qs, 'GET')
            .then((response) => {
                if (response && response.status !== 400 && response !== 500) {
                    getTotalReceivedAndAllocated(dispatch, eventId, agencyCode)
                    return dispatch(getTicketAllocationRequest_(response.data))
                }
            })
    }
}

export const generateTicketRequest_ = (payload) => {
    return {
        type: REQUEST_TICKET_ALLOACATED,
        payload
    }
}


export const generateTicketRequest = (body, { ticketIds, agencyCode, eventId }, emailSentParams) => {
    return (dispatch) => {
        callDynamicApi(`evtech_ticketrequests`, 'POST', body)
            .then((response) => {
                if (response && response.status !== 400 && response !== 500) {
                    let ticketUpdatePs = [];
                    if (response.status === 204) {
                        toast.success('Ticket Request has been successfully generated');
                        if (response.headers['odata-entityid']) {
                            let guid = response.headers['odata-entityid'].split('(')[1].split(')')[0];
                            const bodyParams = {
                                "cr676_event_name": body.cr676_event_name,
                                "evtech_requeststatus": 476940000,
                                "cr676_agency_id_for_client@odata.bind": `evtech_ticketrequests(${guid})`
                            }
                            if (Object.entries(ticketIds).length !== 0) {
                                ticketUpdatePs = ticketIds.map((ticketId) => {
                                    return insertKey(ticketId, bodyParams)(dispatch);
                                })
                            }
                        }
                    }
                    dispatch(generateTicketRequest_(response.data));

                    return Promise.all(ticketUpdatePs);
                }
            })
            .then(() => {
                if (emailSentParams) {
                    // sendEmail(emailSentParams)(dispatch);
                    sendGenerateTicketsEmail(emailSentParams)(dispatch);
                }
                if (agencyCode, eventId)
                    getEventClientRequests(agencyCode, eventId)(dispatch);

            })
    }
}


export const generateAgencyTicketRequest = (body, { ticketIds, agencyCode, eventId }, emailSentParams, firstCall, agency_guid) => {
    return (dispatch) => {
        callDynamicApi(`evtech_ticketrequests`, 'POST', body)
            .then((response) => {
                if (response && response.status !== 400 && response !== 500) {
                    let ticketUpdatePs = [];
                    // console.log('response val', response)
                    if (response.status === 204) {
                        if (body) {
                            let headerId = response.headers['odata-entityid'].split('(')[1].split(')')[0]
                            // localStorage.setItem('headerId', headerId)
                            callDynamicApi(`evtech_ticketrequests(${headerId})`, 'PATCH', { evtech_requeststatus: 807710002 })

                            callDynamicApi(`evtech_ticketrequests`, 'POST', firstCall)
                                .then((response) => {
                                    if (response && response.status !== 400 && response !== 500) {
                                        let headerId2 = response.headers['odata-entityid'].split('(')[1].split(')')[0]
                                        const bodyParams2 = {
                                            "cr676_event_name": body.cr676_event_name,
                                            "evtech_requeststatus": 476940000,
                                            "cr676_agency_id_for_client@odata.bind": `evtech_ticketrequests(${headerId2})`
                                        }
                                        callDynamicApi(`evtech_ticketrequests(${headerId})`, 'PATCH', bodyParams2)

                                    }
                                })
                            callDynamicApi('evtech_ticketrequests?$filter=_evtech_agencyid_value eq ' + agency_guid + ' and _evtech_eventid_value eq ' + eventId + ' and cr676_delete_status ne true &$expand=evtech_agencyid($select=name)', 'GET');
                            let url = window.unescape('evtech_ticketrequests?$filter=_evtech_agencyid_value eq ' + agency_guid + ' and _evtech_eventid_value eq ' + eventId + ' and cr676_delete_status ne true &$expand=evtech_agencyid($select=name)');

                            callDynamicApi(url, 'GET')
                                .then((response) => {
                                    if (response && response.code !== 400 && response.code !== 500) {
                                        return dispatch(getEventAgencyRequest_(response.data))
                                    }
                                })
                        }
                        toast.success('Ticket Request has been successfully generated');
                        if (response.headers['odata-entityid']) {
                            let guid = response.headers['odata-entityid'].split('(')[1].split(')')[0];
                            const bodyParams = {
                                "cr676_event_name": body.cr676_event_name,
                                "evtech_requeststatus": 476940000,
                                "cr676_agency_id_for_client@odata.bind": `evtech_ticketrequests(${guid})`
                            }
                            if (Object.entries(ticketIds).length !== 0) {
                                ticketUpdatePs = ticketIds.map((ticketId) => {
                                    return insertKey(ticketId, bodyParams)(dispatch);
                                })
                            }
                        }
                    }
                    // callDynamicApi('evtech_ticketrequests?$filter=_evtech_agencyid_value eq ' + agency_guid + ' and _evtech_eventid_value eq ' + eventId + ' and cr676_delete_status ne true &$expand=evtech_agencyid($select=name)');

                    dispatch(generateTicketRequest_(response.data));

                    return Promise.all(ticketUpdatePs);
                }
            })
            .then(() => {
                if (emailSentParams) {
                    // sendEmail(emailSentParams)(dispatch);
                    // sendGenerateTicketsEmail(emailSentParams)(dispatch);
                }
                if (agencyCode, eventId)
                    getEventClientRequests(agencyCode, eventId)(dispatch);

            })
    }
}

export const insertKey_ = (payload) => {
    return {
        type: INSERT_HEADER_AS_KEY,
        payload
    }
}

export const insertKey = (ticketReqID, body) => {
    return async (dispatch) => {
        return callDynamicApi(`evtech_ticketrequests(${ticketReqID})`, 'PATCH', body)
            .then((response) => {
                if (response && response.code !== 400 && response !== 500) {
                    return dispatch(insertKey_(response.data))
                }
            })
    }
}

const allocateTicket_ = (payload) => {
    return {
        type: ALLOCATE_TICKET,
        payload
    }
}

export const allocateTicket = (ticketId, body, eventId, agencyCode) => {
    return async (dispatch) => {
        return callDynamicApi(`evtech_ticketrequests(${ticketId})`, 'PATCH', body)
            .then((response) => {
                if (response && response.code !== 400 && response !== 500) {
                    getTotalReceivedAndAllocated(dispatch, eventId, agencyCode)
                    return dispatch(allocateTicket_(response.data))
                }
            })
    }
}

export const getTotalReceivedAndAllocated = (dispatch, eventId, agencyCode) => {
    const qs = `evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_eventid_value eq '${eventId}' and evtech_agencyid eq null &$apply=aggregate(evtech_ticketsassigned with sum as total_allocated, evtech_actualtotaltickets with sum as total_requested)`
    return callDynamicApi(qs, 'GET')
        .then((response) => {
            if (response && response.status !== 400 && response !== 500) {
                return dispatch(totalReceivedAndAllocatedTickets_(response.data))
            }
        })
};

const totalReceivedAndAllocatedTickets_ = (payload) => {
    return {
        type: TOTAL_RECEIVED_ALLOCATED_TICKET,
        payload
    }
}

export const totalReceivedAndAllocatedTickets = (agencyCode, eventId) => {
    return async (dispatch) => {
        const qs = `evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_eventid_value eq '${eventId}' and evtech_agencyid eq null &$apply=aggregate(evtech_ticketsassigned with sum as total_allocated, evtech_actualtotaltickets with sum as total_requested)`
        return callDynamicApi(qs, 'GET')
            .then((response) => {
                if (response && response.status !== 400 && response !== 500) {
                    return dispatch(totalReceivedAndAllocatedTickets_(response.data))
                }
            }).then(
                () => { getTicketAllocationRequest(agencyCode, eventId)(dispatch) }
            )
    }
}

const getAgencyTotalTickets_ = (payload) => {
    return {
        type: TOTAL_AGENCY_TICKET,
        payload
    }
}

export const sendEmail_ = (payload) => {
    return {
        type: SEND_EMAIL,
        payload
    }
}

export const getAgencyTotalTickets = (agencyId, eventId) => {
    return async (dispatch) => {
        const qs = `evtech_ticketrequests?$filter=_evtech_eventid_value eq '${eventId}' and _evtech_agencyid_value eq '${agencyId}' and (evtech_requeststatus eq 807710002 or evtech_requeststatus eq 476940002) and _cr676_agency_id_for_client_value eq null &$apply= aggregate(evtech_ticketsassigned with sum as agency_total_tickets)`;
        //const qs = `evtech_ticketrequests?$filter=_evtech_eventid_value eq '${eventId}' and _createdby_value eq '${createdBy}' and (evtech_requeststatus eq 807710002 or evtech_requeststatus eq 476940002) and _cr676_agency_id_for_client_value eq null &$apply= aggregate(evtech_totaltickets with sum as agency_total_tickets)`
        return callDynamicApi(qs, 'GET')
            .then((response) => {
                if (response && response.status !== 400 && response !== 500) {
                    return dispatch(getAgencyTotalTickets_(response.data))
                }
            })
    }
}

export const getRequestTotalTickets_ = (payload) => {
    return {
        type: GET_REQUEST_TICKET,
        payload
    }
}

export const getRequestTotalTickets = (agencyCode, eventId) => {
    return async (dispatch) => {
        const qs = `evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_eventid_value eq '${eventId}' and evtech_agencyid ne null &$apply= aggregate(evtech_totaltickets with sum as total_requested_by_agency)`
        return callDynamicApi(qs, 'GET')
            .then((response) => {
                if (response && response.status !== 400 && response !== 500) {
                    return dispatch(getRequestTotalTickets_(response.data))
                }
            })
    }
}

export const getRequestTickets = (dispatch, agencyCode, eventId) => {
    const qs = `evtech_ticketrequests?$filter=cr676_agency_code eq '${agencyCode}' and _evtech_eventid_value eq '${eventId}' and evtech_agencyid ne null &$apply= aggregate(evtech_totaltickets with sum as total_requested_by_agency)`
    return callDynamicApi(qs, 'GET')
        .then((response) => {
            if (response && response.status !== 400 && response !== 500) {
                return dispatch(getRequestTotalTickets_(response.data))
            }
        })
}

export const sendEmail = (body) => {
    return async (dispatch) => {
        return callApi('email-ticket-status/', 'POST', body)
            .then((response) => {
                if (response.code === 200) {
                    return dispatch(sendEmail_(response))
                }
            })
    }
}

export const sendGenerateTicketsEmail = (body) => {
    return async (dispatch) => {
        return callApi('email-generate/', 'POST', {'content': body })
            .then((response) => {
                if (response?.code === 200 || response?.status === 200) {
                    return dispatch(sendEmail_(response))
                }
            })
    }
}

const getCountsFromBatchResponse = (batchResString) => {
    const valueRegex = /"@odata.count":(\d)/gm;
    let values = [];
    let match;
    do {
        match = valueRegex.exec(batchResString);
        try {
            const value = JSON.parse(match[1]);
            values = values.concat(value);
        } catch (err) {
            // pass
        }
    } while (match)

    return values;
}

const getValuesFromBatchResponse = (batchResString) => {
    //   const valueRegex = /"value":\s*\[([^\]]+)\]/gm;
    const valueRegex = /"value":\s*\[([^\]]+)\]|\s*\[()\]/gm;
    let values = [];
    let match;
    do {
        match = valueRegex.exec(batchResString);
        try {
            const value = (match[1] == undefined) ? JSON.parse('{}') : JSON.parse(match[1]);
            values = values.concat(value);
        } catch (err) {
        }
    } while (match)
    return values;
}

