/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginUser, dynamicLoginToken, fetchtokenUser } from '../../../actions/AuthActions';
import { DefaultInput, DefaultButton } from '../../UI/atoms/index';
import { Container, Form, FormGroup } from 'reactstrap';
import Logo from './../../../assets/images/logo.jpg';
import { validate, checkValid } from '../../../utils/validations';
import { toast } from "react-toastify";
// import CryptoJS from "crypto-js";
import {
  APP_DYNAMICS_RESOURCE,
  DYNAMICS_CLIENT_ID,
  DYNAMICS_CLIENT_SECRET,
  DYNAMICS_GRANT_TYPE
} from "../../../utils/constants";

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formValues: {
        email: '',
        password: '',
      },
      validations: {
        emailV: '',
      },
      loading: false,
    }
  }

  userLogin = (e) => {
    e.preventDefault();
    let props = this.props;
    const { formValues } = this.state;
    props.loginUserFn(formValues);
  };

  handleChange = (e) => {
    const { formValues } = this.state;
    let { validations } = this.state;
    const { name, value } = e.target;

    if (name === 'email') {
      validations = validate(validations, name, value);
    }

    formValues[[name]] = value;
    this.setState({ formValues, validations });
  };

  checkValidation = () => {
    const { validations } = this.state;
    const check = checkValid(validations);
    if (check) {
      return true;
    }
    return false;
  };

  componentWillReceiveProps(props) {
  }

  componentDidMount() {
    // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(DYNAMICS_CLIENT_ID), 'my-secret-key@123').toString();
    // console.log('ciphertext', ciphertext)
    let data = {
      client_id: DYNAMICS_CLIENT_ID,
      grant_type: DYNAMICS_GRANT_TYPE,
      client_secret: DYNAMICS_CLIENT_SECRET,
      resource: APP_DYNAMICS_RESOURCE
    };
    this.props.dynamicLoginTokenFn(data);
    setInterval(() => { this.props.dynamicLoginTokenFn(data); }, 9 * 60 * 1000);

    if (this.props.user) {
      this.props.history.push('/agency/dashboard');
      // toast.success('user login successfully.');
    }
  }


  render() {
    const { formValues: { email, password }, validations: { emailV } } = this.state;
    return (
      <div className="login-outer">
        <Container>
          <div className="login">
            <div className="login-left">
              <img src={Logo} alt="Kids Upfront Logo" />
              <div className="login-left-bottom"></div>
            </div>

            <div className="login-right">
              <h2>Login</h2>
              <Form onSubmit={this.userLogin}>
                <FormGroup>

                  <DefaultInput
                    name="email"
                    type="email"
                    onChange={this.handleChange}
                    value={email}
                    placeholder="Email"
                    validation={emailV}
                  />
                </FormGroup>
                <FormGroup>

                  <DefaultInput
                    name="password"
                    type="password"
                    onChange={this.handleChange}
                    value={password}
                    placeholder="Password"
                  />
                </FormGroup>
                <DefaultButton
                  name="Login"
                  color="primary"
                  // onClick={this.userLogin}
                  type="submit"
                  block={true}
                  disabled={this.checkValidation() || !password}
                />
              </Form>
              {/* <span className="account"> <Link to={"/agency/register"}>Register your Agency </Link></span> */}
              <span className="account"> <Link to={"/forget-password"}>Forgot Password ?</Link></span>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user
  }
};

const mapDispatch = (dispatch) => ({
  dynamicLoginTokenFn: (data) => dispatch(dynamicLoginToken(data)),
  loginUserFn: user => dispatch(loginUser(user))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Login));
