import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Wrapper from './components/HOC/Wrapper'
import {
    Login,
    Landing,
    Signup,
    ContactUs,
    PageNotFound,
    ForgetPassword,
    ResetPassword,
} from './components/pages';

import { Dashboard, ChangePassword, Profile } from './components/pages/Agency';
import { EventsList } from './components/pages/Agency/EventsManagement';
import { EventDetail } from './components/pages/Agency/EventsManagement';
import { agencyRequest, clientRequest, ticketAllocation } from './components/pages/Agency/Experience';
import RequireAuth from "./components/HOC/RequireAuth";

export default class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    {/*Guest Pages*/}
                    <Route exact path="/" component={Wrapper(Login)} />
                    <Route exact path="/landing" component={Wrapper(Landing)} />
                    <Route exact path="/contact-us" component={Wrapper(ContactUs)} />
                    <Route exact path="/agency/register" component={Wrapper(Signup)} />
                    <Route exact path="/forget-password" component={Wrapper(ForgetPassword)} />
                    {/* <Route exact path="/reset-password/:token" component={Wrapper(ResetPassword)} /> */}
                    <Route exact path="/reset-password/:token" component={Wrapper(ResetPassword)} />

                    {/*Agency Pages*/}
                    <Route exact path="/agency/events" component={Wrapper(RequireAuth(EventsList))} />
                    <Route exact path="/agency/event-detail/:id" component={Wrapper(RequireAuth(EventDetail))} />
                    <Route exact path="/agency/dashboard" component={Wrapper(RequireAuth(Dashboard))} />

                    <Route exact path="/agency/change-password" component={Wrapper(ChangePassword)} />
                    <Route exact path="/agency/profile" component={Wrapper(RequireAuth(Profile))} />
                    <Route exact path="/experience/agency-request" component={Wrapper(RequireAuth(agencyRequest))} />
                    <Route exact path="/experience/client-request" component={Wrapper(RequireAuth(clientRequest))} />
                    <Route exact path="/experience/ticket-allocation" component={Wrapper(RequireAuth(ticketAllocation))} />


                    <Route component={PageNotFound} /> {/* Not Page Found  */}
                </Switch>
            </Router>
        )
    }
}
