/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter , Link} from 'react-router-dom';
import {DefaultInput, DefaultButton, Icon} from '../../../UI/atoms/index';
import {Col, Container, Form, FormGroup, Row} from 'reactstrap';
import { validate, checkValid } from '../../../../utils/validations';
import {changePasswordFn} from "../../../../actions/AuthActions";
import {connect} from "react-redux";
class ChangePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formValues: {
        old_password: '',
        password: '',
        confirm_password: '',
      },
      validations: {
        current_passwordV: null,
        passwordV: null,
        password_confirmationV: null,
      },
      loading: false,
    }
  }

  submitResetPassword = (e) => {
    e.preventDefault();
    this.submitChangePasswordFn()
  };

  submitChangePasswordFn = async () => {
    const { formValues } = this.state;
    const  {history, dynamic, user} = this.props;
    let email = user?.data?.email;
    let contactId = dynamic.contactid;
    localStorage.setItem('contactId', dynamic.contactid)
    await this.props.changePasswordFn(formValues, email, history, contactId);
  }

  handleChange = (e) => {
    const { formValues } = this.state;
    let { validations } = this.state;
    const { name, value } = e.target;

    formValues[[name]] = value;

    if (name == 'password') {
      validations = validate(validations, name, value, formValues['password']);
    } else if (name == 'password_confirmation') {
      validations = validate(validations, name, value, formValues['password_confirmation']);
    } else if (name == 'old_password') {
      validations = validate(validations, name, value, formValues['old_password']);
    } else {
      validations = validate(validations, name, value, formValues.password);
    }

    this.setState({ formValues, validations});
  };

  checkValidation = () => {
    const { validations } = this.state;
    const check = checkValid(validations);

    if (check) {
      return true;
    }
    return false;
  };

  componentWillReceiveProps(props) {

  }

  componentDidMount() {
   /* if (this.props.user) {
      if (this.props.user) {
        this.props.history.push('/agency/dashboard');
      }
    }*/
  }


  render() {
    const { formValues: { old_password, password, confirm_password }, validations: { current_passwordV, passwordV, password_confirmationV } } = this.state;
    return (
        <div className="dashboard">
          <Container fluid={true}>
            <div className="dashboard-heading">
                <span className="section-icon">
                  <Icon name="user"/>
                </span>
              <h4>CHANGE PASSWORD</h4>
            </div>

            <Row className="dashboard-white-outer">
              <Col className="dashboard-content-left col-xxl-3" xs="12" sm="12" md="12" lg="12" xl="4">
                <Form>
                  <FormGroup>
				    <label>Current Password<span style={{color: "red"}}>*</span></label>
                    <DefaultInput
                        name="old_password"
                        type="password"
                        onChange={this.handleChange}
                        value={old_password}
                        validation={current_passwordV}
                        placeholder="Current Password"
                    />
                  </FormGroup>
                  <FormGroup>
				   <label>New Password<span style={{color: "red"}}>*</span></label>
                    <DefaultInput
                        name="password"
                        type="password"
                        onChange={this.handleChange}
                        value={password}
                        validation={passwordV}
                        placeholder="New Password"
                    />
                  </FormGroup>

                  <FormGroup>
				    <label>Confirm Password<span style={{color: "red"}}>*</span></label>
                    <DefaultInput
                        name="confirm_password"
                        type="password"
                        onChange={this.handleChange}
                        value={confirm_password}
                        validation={password_confirmationV}
                        placeholder="Confirm Password"
                    />
                  </FormGroup>

                  <DefaultButton
                      name="Submit"
                      color="primary"
                      onClick={this.submitResetPassword}
                      block="true"
                      disabled={this.checkValidation()}
                  />
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
    );
  }
}

 function mapStateToProps(state) {
   return {
    dynamic: state.authReducer.dynamic_user_detail,
	user: state.authReducer.user
  };
  }
  
    const mapDispatch = dispatch => ({
    changePasswordFn: (data, history, dynamic) => dispatch(changePasswordFn(data, history, dynamic))
	
  });
export default withRouter(connect(mapStateToProps, mapDispatch)(ChangePassword))