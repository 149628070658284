const API_URL = process.env.REACT_APP_API_END_SERVER;
const MEDIA_URL = process.env.REACT_APP_API_END_SERVER_MEDIA;
const DYNAMICS_URL = process.env.REACT_APP_DYNAMICS_API_END_SERVER;
const DYNAMICS_API_URL = process.env.REACT_APP_DYNAMICS_API_SERVER;

const DYNAMICS_CLIENT_ID = process.env.REACT_APP_DYNAMICS_CLIENT_ID;
const DYNAMICS_GRANT_TYPE = process.env.REACT_APP_DYNAMICS_GRANT_TYPE;
const DYNAMICS_CLIENT_SECRET = process.env.REACT_APP_DYNAMICS_CLIENT_SECRET;
const APP_DYNAMICS_RESOURCE = process.env.REACT_APP_DYNAMICS_RESOURCES;
const DYNAMICS_LOGIN_URL = process.env.REACT_APP_DYNAMICS_LOGIN_URL;
const WEB_URL = process.env.REACT_APP_WEB_URL;


const INIT = 'INIT';
const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';

export {
    API_URL,
    MEDIA_URL,
    WEB_URL,
    DYNAMICS_URL,
	DYNAMICS_API_URL,
    DYNAMICS_LOGIN_URL,
    DYNAMICS_CLIENT_ID,
    DYNAMICS_GRANT_TYPE,
    DYNAMICS_CLIENT_SECRET,
    APP_DYNAMICS_RESOURCE,
    INIT,
    LOADING,
    SUCCESS,
    ERROR
}
