import React from 'react';
import {Collapse, Navbar, NavbarBrand, Nav, NavItem, NavLink} from 'reactstrap';
import Logo from './../../../assets/images/logo.jpg';
import Logout from "../../UI/molecules/Logout";

class Header extends React.Component {
  state = {
    isLoader: false
  }

  render() {
    return (
    <header className="user-header">
    <Navbar light expand="md">
     <NavbarBrand href="/"><img src={Logo} alt=""/></NavbarBrand>

       <Nav className="ml-auto" navbar>
         <NavItem>
            <Logout />
         </NavItem>

       </Nav>
    </Navbar>
  </header>
    );
  }
}

export default (Header);
