/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter , Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { getCategories } from '../../../actions/simpleActions';

class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      venueCategoriesData: [],
    }
  }

  componentWillMount() {
    this.props.getCategories();
  }

  componentWillReceiveProps(props) {
    if (props.venue_categories && props.venue_categories.categories) {
      this.setState({ venueCategoriesData: props.venue_categories.categories })
    }
  }

  render() {
    const {venueCategoriesData} = this.state;
    return (
      <Link to="/">Contact Us page</Link>
    );
  }
}

const mapStateToProps = (state) => ({
  venue_categories: state.simpleReducer.get_categories,
});

const mapDispatch = (dispatch) => ({
  getCategories: () => dispatch(getCategories()),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ContactUs));
