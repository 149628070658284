import React from 'react'

function TermsAndConditions(){

    return (
        <div id='term-condition'>
            <p align="center"><strong>AGENCY AGREEMENT</strong></p>
              <p><strong>WHEREAS</strong> Kids Up Front obtains entertainment, educational and recreational event tickets from
various donors (the "<strong>Event Tickets</strong>") and works with various agencies, including but not limited
to: registered charities, non-profit societies, government agencies, and schools to provide
opportunities to high needs children, youth and families facing financial and/or social barriers in
our community;</p>

              <p><strong>AND WHEREAS</strong> the Agency agrees to enter into this Agency Agreement (the "<strong>Agreement</strong>") to
be eligible to receive the Event Tickets from Kids Up Front;</p>

              <p><strong>AND WHEREAS</strong> this Agreement shall govern the terms and conditions by which the Event
Tickets: (i) are distributed by Kids Up Front, in its sole discretion, to the Agency; and (ii) are
subsequently distributed by the Agency to youth and children, of 18 years age and younger, who
are connected with the Agency and are experiencing financial and/or social barriers, along with
their respective parents, guardians or supervisors, as the case may be (the "<strong>Recipients</strong>");</p>

              <p><strong>NOW THEREFORE</strong>, in consideration of Kids Up Front providing the Event Tickets to the Agency
and such further and other valuable consideration, the receipt and sufficiency of which is hereby
acknowledged, the Parties hereby acknowledge and agree as follows:</p>

              <p><strong>1. RELATIONSHIP OF THE PARTIES</strong></p>

              <p>1.1 The Parties agree and acknowledge that they must execute this Agreement in order for the Agency to become eligible to receive Experiences.</p>

              <p>1.2 The Agency acknowledges and agrees that it is not an employee, agent, or partner of Kids Up Front and at no time will the Agency represent or hold itself out to have any relationship with Kids Up Front other than as a Party to the Agreement.</p>

              <p><strong>2. REPRESENTATIONS AND WARRANTIES OF THE AGENCY</strong></p>

              <p>2.1 The Agency represents and warrants that it is a corporation, organization or other entity, in good standing under the laws under which it is constituted, and has all necessary power, authority and capacity to enter into and carry out its obligations under the Agreement.</p>

            <p> 2.2 Kids Up Front represents and warrants that it is a corporation, organization, or other
entity, in good standing under the laws under which it is constituted, and has all
necessary power, authority and capacity to enter into and carry out its obligations under
the Agreement.</p>
              <p><strong>3. OBLIGATIONS OF THE AGENCY</strong></p>

              <p>3.1 The Agency acknowledges and agrees that:
(a) it has received, reviewed, and understands the policies of Kids Up Front as set
out in the Kids Up Front Agency Policy Handbook which may be amended from
time to time (the "<strong>Kids Up Front Policies</strong>"), attached as Schedule "A";
(b) it shall abide by the Kids Up Front Policies, including all amendments which are
notified to the Agency in writing, and the Kids Up Front Policies and all
amendments thereto are incorporated into and form part of this Agreement.</p>

              <p>3.2 The Agency shall appoint one or more key contact persons for Kids Up Front (each, an
"<strong>Experience Coordinator</strong>"). The Agency covenants to: (i) provide Kids Up Front with
the contact information for each Experience Coordinator; and (ii) provide prompt written
notice to Kids Up Front of any and all changes to an Experience Coordinator, and/or
such Experience Coordinator’s contact information to facilitate communication between
Kids Up Front and the Agency, and to allow Kids Up Front to promptly contact each
Experience Coordinator to disseminate Event Tickets.</p>
              <p>3.3 Each Experience Coordinator is responsible for carrying out the Agency's responsibilities
under this Agreement, which include but are not limited to: (i) attending training as
prescribed by Kids Up Front from time to time; and (ii) offering Event Tickets to potential
Recipients in a timely fashion.</p>
              <p>3.4 The Agency agrees that it will not sell or exchange the Event Tickets under any
circumstances for any form of compensation, including but not limited to, any monetary
compensation, goods, or services, and that it will not distribute or provide the Event
Tickets to individuals who are not Recipients. Without limiting the generality of the
foregoing, the Agency agrees that it shall not, under any circumstances, be allowed
to use Event Tickets: (i) directly or indirectly for Agency fundraising purposes; (ii) to
compensate the staff of the Agency; or (iii) for any other internal purpose.</p>
              
              <p>3.5 The Agency agrees that it shall only distribute the Event Tickets to Recipients and shall
not distribute the Event Tickets to staff or volunteers unless those staff or volunteers are
acting as chaperone to the Recipients, and that it shall take reasonable steps to ensure
that the Recipients that received the Event Tickets, use the Event Tickets. If Recipients
are unable to use any Event Tickets, the relevant Experience Coordinator(s) are required
to notify Kids Up Front and to provide a reasonable explanation.</p>
              <p>3.6 Event Tickets may be distributed electronically, available for pick-up at the Kids Up Front
office, or at will call at the event venue. The Agency agrees that it is responsible for
picking up all physical Event Tickets, as applicable or required, at either the will call desk
at the event venue or at Kids Up Front office.</p>
              <p>3.7 The Agency agrees that it shall make all reasonable efforts to ensure Event Tickets are
allocated and distributed to Recipients who are available to use the tickets, and if it is
unable to identify any such Recipients or has Event Tickets returned, that the Event
Tickets must be returned to Kids Up Front (even if under 24 hours' notice). The Parties
acknowledge that Kids Up Front is not open on the weekends and that efforts to return
the Event Tickets shall be made in accordance with such acknowledgement.</p>
              <p>3.8 The Agency shall bear the risk of and be responsible for any lost, stolen, or missing
Event Tickets. If Event Tickets are lost, stolen, or otherwise missing, the Agency shall
notify Kids Up Front immediately. Kids Up Front is not responsible for replacing lost,
stolen, or missing Event Tickets.</p>
              <p>3.9 The Agency shall inform the Recipients that the Recipients must conform to the
standards of behaviour required by this Agreement and Kids Up Front Policies, including
the prohibition on the consumption of drugs and/or alcohol on or at the event site,
before, during and after attending any event accessed with Event Tickets, and that
failure to adhere to the standards of behaviour required by this Agreement and Kids Up
Front Policies may result in the relevant Recipients becoming ineligible for any future
Event Tickets, in Kids Up Front’s sole discretion. The Parties acknowledge that with the
exception of instances when the Agency’s staff or volunteers are chaperoning the
Recipients before, during, or after attending an event accessed with Event Tickets, the
Agency has limited ability to monitor the conduct of the Recipients.</p>
<p>3.10 The Agency shall provide Kids Up Front with a written report specifying the use of the
Event Tickets (the "<strong>Report</strong>") when requested by Kids Up Front to provide such Report.
Kids Up Front reserves the right to request a Report at any time.</p>

              <p><strong>4. INSURANCE</strong></p>

              <p>4.1 The Agency shall obtain and maintain general liability and all risks insurance in a form and substance that covers the use of this Agreement and in an amount not less than $2,000,000 in Canadian dollars (the "<strong>Insurance</strong>"). Kids Up Front is under no obligation to provide any general liability and all risks insurance to the Agency.</p>

              <p>4.2 The Agency shall provide the certificates of insurance (the "<strong>Certicate</strong>") at the date of
execution of this Agreement, at each renewal date of such insurance and from time to
time upon the reasonable request of Kids Up Front. In the event that the Agency does
not have Insurance as of such date, the Agency shall provide the Certificate no later
than 15 Business Days following execution of this Agreement. For the purposes of this
Agreement, a “<strong>Business Day</strong>” shall mean any day other than a Saturday, Sunday or any
other day on which banks located in the Province where the Agency is located in, are
authorized or required by law to be closed for business.</p>

              <p><strong>5. LIABILITY AND INDEMNITY</strong></p>

              <p>5.1 The Agency shall indemnify, defend and hold each director, officer, employee, contractor, volunteer, agent and assign of Kids Up Front (each a “<strong>Kids Up Front
Indemni ed Person</strong>”) harmless from and against any and all losses, damages, liabilities,
deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines,
costs, or expenses of whatever kind, including legal fees, charges, and disbursements,
and the cost of pursuing any insurance providers ("<strong>Loss</strong>") resulting from any bodily
injury, death of any person, or damage to real or personal property in connection with
the acceptance, use and distribution of the Event Tickets by the Agency or the use of
the Event Tickets and attendance at any such events by the Recipient(s) arising out of
any negligence, breach of duty of care, or omission of the Agency in the performance of
its obligations under this Agreement, including but not limited to failure to abide by the
Kids Up Front Policies, except those Claims (as defined below) directly resulting from
Kids Up Front’s gross negligence, willful misconduct or bad faith failure to materially
comply with any of its material obligations set forth in this Agreement.</p>

              <p>5.2 Kids Up Front shall indemnify, defend and hold each director, officer, employee,
contractor, volunteer, agent and assign of Agency (each an “<strong>Agency Indemni ed
Person</strong>”) harmless from and against any and all Loss resulting from any bodily injury,
death of any person, or damage to real or personal property in connection with the
acceptance, use and distribution of the Event Tickets by the Agency or the use of the
Event Tickets and attendance at any such events by the Recipient(s) caused by the gross
negligence or willful misconduct of Kids Up Front, except those Claims (as defined
below) directly resulting from Agency's negligence, willful misconduct or bad faith failure
to materially comply with any of its material obligations set forth in this Agreement.</p>
              <p>5.3 The Party being indemnified under this Agreement (the “<strong>Indemnified Party</strong>”) shall
promptly notify the Party providing the indemnity under this Agreement (the
“<strong>Indemnifying Party</strong>”) in writing of the discovery of any facts or circumstances that the
Indemnified Party has determined could give rise to an indemnification claim under this
section (a “<strong>Claim</strong>”), and if requested by the Indemnifying Party, cooperate with the
Indemnifying Party at the Indemnifying Party’s sole cost and expense. The Indemnified
Party shall take all reasonable steps to mitigate any Loss upon becoming aware of any
event or circumstance that would be reasonably expected to, or does, give rise thereto,
including incurring costs only to the minimum extent necessary to remedy the breach
that gives rise to such Loss.</p>
              <p>5.4 The Indemnifying Party shall take control of the defense and investigation of the Claim,
at the Indemnifying Party’s sole cost and expense. The Indemnifying Party shall not
settle any Claim in a manner that adversely affects the rights of the indemnified Party
without the Indemnified Party’s prior written consent, unless the settlement results in a
complete release of the Indemnified Party from all Claims. The Indemnified Party may
participate in and observe the proceedings for the Claim at its own cost and expense
with counsel of its own choosing.</p>
                <p> 5.5 The Indemnified Party’s failure to perform any obligations under this section 5 does not
relieve the Indemnifying Party of its obligations under this section except to the extent
that the Indemnifying Party can demonstrate that its ability to perform its obligations under this section has been materially prejudiced as a result of the Indemnified Party’s
failure.</p>
<p> 5.6 Neither Party nor its personnel shall be liable for consequential, indirect, or incidental
damages arising out of or relating to any breach of this Agreement, whether or not the
possibility of such damages has been disclosed in advance by the other Party or could
have been reasonably foreseen by the other Party, regardless of the legal or equitable
theory upon which the Claim is based.</p>
<p> 5.7 In no event shall either Party’s aggregate liability arising out of or related to this
Agreement, whether arising out of or related to breach of contract, tort (including
negligence), or otherwise, exceed $500.</p>

              <p><strong>6. TERMINATION</strong></p>

              <p>6.1 Either Party may terminate this Agreement upon seven business days written notice to
the other Party. Once the seven business days lapse, the relationship between the
Parties shall be deemed terminated and the Agency will immediately return any
undistributed Event Tickets to Kids Up Front.</p>

              <p>6.2 The Agency acknowledges and agrees that if it breaches this term or any other term of
this Agreement that any distribution of Event Tickets may be suspended at the sole
discretion of Kids Up Front, or Kids Up Front may terminate this Agreement effective
immediately.</p>

              <p><strong>7. PRIVACY AND PROMOTIONAL MATERIAL</strong></p>

              <p>7.1 The Agency acknowledges and agrees that it is responsible for compliance at all times with applicable privacy laws which govern the collection, use and disclosure of any and all personal information collected, used or disclosed by the Agency in connection with the Agreement and its relationship with Kids Up Front, including but not limited to the Personal Information Protection and Electronic Documents Act ("<strong>PIPEDA</strong>")and all
applicable provincial laws relating to the processing, protection, or privacy of personal
information (the "<strong>Privacy Laws</strong>").</p>
              <p>7.2 Kids Up Front agrees that all information it collects shall be collected, used, and
disclosed in accordance with the Kids Up Front Privacy Policy (the "<strong>Kids Up Front
Privacy Policy</strong>"), attached hereto as Schedule "B", as may be amended from time to
time.</p>
              <p>7.3 The Agency, alone or in cooperation with Kids Up Front, agrees to make every effort to
gain written consent from the Recipients, in a form acceptable to Kids Up Front,
regarding the Recipients' participation in any Kids Up Front promotional events
including but not limited to photographs, video, and social media.</p>
              <p>7.4 The Parties agree that they will obtain the other Party’s prior written approval and
consent prior to the use of the other Party’s logos or promotional materials.</p>
<p>7.5 The Agency acknowledges that Kids Up Front may use impact stories from Recipients
from the Agency, arising from the use of an Event Ticket by a Recipient, and these
impact stories may be posted on the Kids Up Front website or other forms of social
media and may be used in Kids Up Front advertising or funding proposals from time to
time. Kids Up Front shall obtain consent, as applicable, from a Recipient or guardian
prior to using a Recipient’s impact story, in accordance with applicable Privacy Laws and
Kids Up Front’s Privacy Policy.</p>

        <p><strong>8. ADDITIONAL PROGRAMMING</strong></p>
        <p>8.1 The Agency may be entitled to participate, at Kids Up Front’s sole discretion, in
additional Kids Up Front programming for the benefit of the Recipients, including the
‘Kids At Home’ program, whereby certain digital resources and activities are made
available for download, the ‘Handled with Care’ program, whereby certain new goods
are made available from third parties by donation, and any other programs offered by
Kids Up Front during the term of this Agreement (such programs collectively known as
the “<strong>Additional Programs</strong>”). The Additional Programs are subject to the terms of this
Agreement and any additional terms and conditions made available to the Agency prior
to the commencement of such activity (including without limitation, any website terms of
use made available in connection with the ‘Kids At Home’ program). Kids Up Front
reserves the right to modify, suspend or cancel the Additional Programs without prior
notice to the Agency or the Recipients for any reason whatsoever.</p>
        <p>8.2 Kids Up Front (and its licensor(s)) reserves and retains its entire right, title, and interest in
and to all intellectual property, including but not limited to copyright, trademarks, logos,
and designs relating to Kids Up Front and/or the Additional Programs.  </p> 

              <p><strong>9. ASSIGNMENT</strong></p>

              <p>9.1 There shall be no transfer or assignment of this Agreement without the prior written consent of Kids Up Front, such consent may be withheld at Kids Up Front's sole discretion.</p>
              <p><strong>10. SEVERABILITY</strong></p>

              <p>10.1 If any provision of the Agreement or its application to any Party or circumstance is determined by a court of competent jurisdiction to be illegal, invalid, or unenforceable, it will be ineffective only to the extent of its illegality, invalidity or unenforceability.</p>

              <p><strong>11. ENTIRE AGREEMENT</strong></p>

              <p>11.1 This Agreement constitutes the entire agreement between the Parties with respect to the subject matter of this Agreement and supersedes all other understandings, agreements, representations (including misrepresentations, negligent, or otherwise), negotiations, communications, and discussions, written, or oral, made by the Parties with respect thereto. There are no representations, warranties, terms, conditions, covenants or other understandings, express or implied, collateral, statutory or otherwise, between the Parties, except as expressly stated in this Agreement. The Parties have not relied and are not relying on any other information, discussion or understanding in entering into and completing the transactions contemplated by this Agreement.</p>

              <p><strong>12. GOVERNING LAW</strong></p>

              <p>12.1 This Agreement will be construed, interpreted, and enforced in accordance with the laws
of the Province of Alberta if this agreement is signed with our Calgary or Edmonton
offices, the Province of British Columbia for our Vancouver office, the Province of
Ontario for our Ottawa and Toronto offices, the Province of Nova Scotia for our Atlantic
office, and the federal laws of Canada applicable therein. Each Party irrevocably attorns
and submits to the jurisdiction of the courts of the corresponding Province.</p>

              <p><strong>13. DELIVERY</strong></p>

              <p>13.1 This Agreement and any amendment, supplement, restatement or termination of any provision of
this Agreement may be executed in any number of counterparts, each of which when executed
and delivered is an original but all of which taken together constitute one and the same
instrument. A Party’s electronic transmission of a copy of this Agreement duly executed by that
Party shall constitute effective delivery by that Party of an executed copy of this Agreement to the
Party receiving the electronic transmission.</p>


<p align="center"><strong>SCHEDULE A</strong></p>
<p><strong>KIDS UP FRONT POLICIES</strong></p>

              <p>1. All Agencies must provide a contact name(s) authorized to receive Kids Up Front
emails and make the distribution and recipient decisions within Kids Up Front
policies. This person is considered by Kids Up Front to be the Experience
Coordinator.</p>
<p>1. All Agencies must provide a contact name(s) authorized to receive Kids Up Front
emails and make the distribution and recipient decisions within Kids Up Front
policies. This person is considered by Kids Up Front to be the Experience
Coordinator.</p>
<p>2. The Experience Coordinator must be familiar and compliant with Kids Up Front
policies.</p>
<p>3. Kids Up Front conducts Agency meetings. Experience Coordinators must attend at
least one meeting per year.</p>
<p>4. Agencies must ensure that the experiences provided by Kids Up Front go to children,
youth (18 and under) experiencing ethnic, financial, health, mental and/or social
barriers. Parents and chaperones are of course encouraged to attend, but there
should always be a higher or equal ratio of kids to adults.</p>
<p>5. Agency staff members are more than welcome to chaperone kids, groups or families
to events. Event Tickets and other opportunities are NOT to be used personally or
given to family or friends. Staff are not allowed to access our tickets for personal use
or for their family unless we specifically indicate it.</p>
<p>6. Only when expressly approved by the donor and Kids Up Front, in writing, to the
respective Agency, can experiences be allocated to Volunteers, Agency Staff, family
and/or friends.</p>
<p>7. Experiences provided to all Agencies must not be sold or given to non-approved or
non-qualified individuals.</p>
<p>8. Event Tickets and other opportunities allocated can be picked up at the Kids Up
Front office or “will call” prior to the event and used as intended or can be
transferred as e-tickets.</p>
<p>9. Agencies must advise Kids Up Front if any experiences allocated to recipients are not
able to be used for the intended purpose. Tickets must be returned to Kids Up Front
even if under 24 hours notice.</p>
<p>10. In the event that allocated Tickets go unused and seats go empty, Kids Up Front may
issue a penalty up to the termination of the Agreement.</p>
<p>11. Consumption of drugs and/or alcohol is not permitted on or at the event site, before,
during and after attending any event accessed with Event Tickets.</p>
<p>12. Respectful behaviour for the use of the property, other event attendees and donor
generosity is expected.</p>
<p>13. Foul language is not permitted at the event.</p>
<p>14. Supervision by a parent, guardian, staff member or volunteer is required for
experiences held in “suites or boxes”.</p>
<p>15. Supervision to enhance the Youth and Children’s experience and personal safety is
encouraged and expected.</p>
<p>16. Kids Up Front sends thank you cards to donors for their generosity. Agencies should
encourage families to create one and send it back.</p>
<p>17. Agencies must advise selected recipients of the source of the opportunities and
expectations of behaviour.</p>
<p>18. Agencies must advise Kids Up Front of any concerns or issues experienced during the
event.</p>
<p>19. Kids Up Front staff or volunteers will attend events, on some occasions, to audit
seats.</p>
<p>20. Agencies must advise recipients to cooperate in the event that a Kids Up Front
representative requests verification of the organization they represent at the event.</p>

<p align="center"><strong>SCHEDULE B</strong></p>
<p><strong>PRIVACY POLICY</strong></p>

<p>Kids Up Front Respects Your Right to Privacy.</p>
<p>When you give your information to Kids Up Front, you are entrusting us with very important
information, and we understand that you may have concerns about privacy issues on the Internet.
Kids Up Front feels that protecting the privacy of our donors and partners is one of our most
important jobs.</p>
<p>Your personal information, although it is shared with kidsupfront.com, belongs to you. When we
refer to “personal information”, we mean any information about an identifiable individual,
whether it concerns your email address and where you live. We feel that it is your right to be able
to give in a safe and secure environment, and to know how your personal information is being
used.</p>
<p>Your personal information will never be shared, traded, sold or used in any manner not explicitly
approved by you. We do not swap mailing lists, share information or sell phone numbers. Only
Kids Up Front is allowed to access this personal information. We are required to store your
personal information by the Canada Revenue Agency (CRA). The only circumstances under which
we would divulge any personal donor information to a third party is upon subpoena or order from
a legitimate court, police agency, the CRA. In any case when your information is shared, Kids Up
Front will demand reasonable assurance that third parties will protect your information in
conformity with our privacy practices.</p>
<p>From time to time, Kids Up Front may share statistics that describe how our visitors use our site.
This might include information like the average donation amount, how donations break down by
geographic area and what times of the day have the heaviest donation traffic. None of these
statistics will ever reveal any personally-identifiable or individual information. This information
allows Kids Up Front to better serve donors and partners, use its marketing resources more
efficiently, and help us understand how to effectively communicate about Kids Up Front to its
donors.</p>

            </div>
          
    )
}

export default TermsAndConditions