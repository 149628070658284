import React from 'react';

export default class Steps extends React.Component{

  render(){
      const {steps:{ step1, step2, step3}, checkValidation, checkValidationStep2, step1Click, step2Click, step3Click} = this.props;

    return(
      <div>
         <ul className="Signup-steps">
           <li onClick={() => 
             step1Click()
             } className={step1 ? 'active': ''}><span>01</span></li>
           <li  onClick={() => 
             !checkValidation ? step2Click() : ""
          } className={step2 ? 'active': ''}><span>02</span></li>
           <li  onClick={() => 
             !checkValidation && !checkValidationStep2 ? step3Click() : ""
          } className={step3 ? 'active': ''}><span>03</span></li>
         </ul>
      </div> 
    )

  }

}
