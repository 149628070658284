import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, Legend, Tooltip } from 'recharts';
import Select from 'react-select';
import FilterDropdown from './FilterDropdown';
import { Container, Row, Col} from 'reactstrap';
const colors = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#6f42c1", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"];
var d = new Date();
var currentYear = d.getFullYear();
 const  filterGraphDataOptions = [
            { name:"filterDataValue" ,value: 'all', label: 'All' },
            { name:"filterDataValue" ,value: currentYear - 2, label: currentYear - 2 },
            { name:"filterDataValue" ,value: currentYear-1, label: currentYear -1  },
            { name:"filterDataValue" ,value: currentYear, label: currentYear }
        ];
	
export default class BarGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                { amount: 2400, },
                { amount: 1398, },
                { amount: 9800, },
                { amount: 3908, },
                { amount: 9000, },
            ],
            filterDataValue: ''
        }
    }
	

    render() {
        const { filterData, graphData, selectValue } = this.props;
	   let filteredGraphData = graphData.filter(function (el) {
            return el != null;
        });
		
        return (
            <div>
                 <div className="mb-3 ml-auto" style={{maxWidth: "200px"}}>
                    <FilterDropdown
					   filterGraphDataOptions = {filterGraphDataOptions}
					    selectValue = {selectValue}
                        onChangeEvent={(event) => {
                            filterData(event.value);
                        }}
                    />
                </div>
                <Row>
                    <Col lg="9">
                        <div className="graph-outer">
                            <BarChart width={750} height={300} data={filteredGraphData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <XAxis dataKey="type" interval = "0"  minTickGap="3"/>
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
								<Tooltip />
                               <Legend />
                                <Bar dataKey="amount" fill="#8884d8" label={{ position: 'top' }}>
                                    {
                                        filteredGraphData && filteredGraphData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                                        ))
                                    }
                                </Bar>
                            </BarChart>
                        </div>
                    </Col>
                    {this.props.showLegends ? (
                        <Col lg="3">
                            <ul className="graph-labels">
                            <li className="blue"><span className="tile"></span> <span className="tile-name">Client Request</span></li>
                            <li className="orange"><span className="tile"></span> <span className="tile-name">Agency Request</span></li>
                            <li className="green"><span className="tile"></span> <span className="tile-name">Experiences Received</span></li>
                            <li className="red"><span className="tile"></span> <span className="tile-name">Experiences Allocated</span></li>
                            </ul>
                        </Col>
                    ) : (
                        <Col lg="3">
                            <ul className="graph-labels">
                            <li className="blue"><span className="tile"></span> <span className="tile-name">Experiences Requested</span></li>
                            <li className="orange"><span className="tile"></span> <span className="tile-name">Experiences Approved</span></li>
                            </ul>
                        </Col>
                    )}
                    </Row>
                
                
                <div>
                </div>
            </div>
        );
    }
}
