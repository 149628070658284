
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import eventReducer from "./eventReducer";
import uiReducer from "./uiReducer";
import dashboardReducer from './dashboardReducer';

export default combineReducers({
  authReducer,
  eventReducer,
  ui: uiReducer,
  dashboard: dashboardReducer,
});

