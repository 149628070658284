/*eslint eqeqeq: "off"*/
import React from 'react';
import ReactLoader from "react-loader-spinner";

class Loader extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {isVisible} = this.props;
        return (
            isVisible ? (
            <div className="loader_div">
                <div className="inner-loader">
                    <ReactLoader
                        className="inner-shadow"
                        visible={true}
                        type="Puff"
                        color="#8AC53A"
                        height={100}
                        width={100}
                        // timeout={3000} //3 secs
                    />
                </div>
            </div>
            ) : null
        );
    }
}


export default Loader;