
/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getClientHoverData } from "../../../../actions/EventActions";
import { Icon } from './../../../UI/atoms/Icon';
import Loader from "./../../../UI/molecules/Loader";
import {
  FormGroup,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

class Client extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }


  modalToggle = () => {
    this.setState({ modal: !this.state.modal })
    if (!this.state.modal) {
      const eventId = this.props.match.params && this.props.match.params.id;
      this.props.getClientHoverData(eventId, this.props.email);
    }
  };

  componentWillMount() {
  }

  render() {

    let { name, getClientData } = this.props;
    const eventRequestRecieved = getClientData ? getClientData[0] : '';
    const otherEventRequestRecieved = getClientData ? getClientData[1] : '';
    const numberOfRequestRecieved = getClientData ? getClientData[3] : '';
    return (
      <>
        {name}
        <span class='info-span' onClick={this.modalToggle}>
          <Icon name="info" size='sm'></Icon>
        </span>

        <Modal isOpen={this.state.modal} toggle={this.modalToggle} className="event-popup modal-dialog-centered ">
          <ModalHeader toggle={this.modalToggle}>Client Requests</ModalHeader>

          <ModalBody>
            <div className="client-modal-content">
              <h6>Current Request</h6>
              <p>Current Ticket Request : {otherEventRequestRecieved ? otherEventRequestRecieved.total_requested : 0}</p>

              <Row>
                <Col xl="6" sm="12">
                  <p>Adult : {otherEventRequestRecieved ? otherEventRequestRecieved.total_adult_requested : 0}</p>
                </Col>
                <Col xl="6" sm="12">
                  <p>Children : {otherEventRequestRecieved ? otherEventRequestRecieved.total_number_of_child : 0}</p>
                </Col>
              </Row>
            </div>

            <div className="client-modal-content">
              <h6>All Request </h6>
              <p>Number of Request : {numberOfRequestRecieved ? numberOfRequestRecieved : 0}</p>

              <p>All Ticket Request: {eventRequestRecieved ? eventRequestRecieved.total_requested : 0}</p>

              <Row>
                <Col xl="6" sm="12">
                  <p>Adult :  {eventRequestRecieved ? eventRequestRecieved.total_adult_requested : 0}</p>
                </Col>
                <Col xl="6" sm="12">
                  <p>Children :  {eventRequestRecieved ? eventRequestRecieved.total_number_of_child : 0}</p>
                </Col>
              </Row>


            </div>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </Modal>

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // dynamic: state.authReducer.dynamic_user_detail,
    //user: state.authReducer.user,
    getClientData: state.eventReducer.getClientData,
  }

}


const mapDispatch = (dispatch) => ({
  getClientHoverData: (eventId, email) => dispatch(getClientHoverData(eventId, email))

  // getFeaturedCategories: (evtech_city) => dispatch(getFeaturedCategories(evtech_city)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Client));

