/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import {
  FormGroup,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { Icon } from './../../../UI/atoms/Icon';
import {
  getExperienceClientRequest
} from '../../../../actions/EventActions';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

class clientRequest extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    if (this.props.agencyCode) {
      this.props.getExperienceClientRequest(this.props.agencyCode);
    }
  }

  tripleHeader = ({ string1, string2, string3 }) => {

    return (
      <table width="100%" className="innerHeading">
        <tr>
          <td>{string1}</td>
          <td>{string2}</td>
          <td>{string3}</td>
        </tr>
      </table>
    )
  }

  totalTicketAllow = (string1, string2, string3) => {
    return (
      <table className="double-conent">
        <tbody>
        <td>{string1}</td>
        <td>{string2} </td>
        <td>{string3} </td>
        </tbody>
      </table>
    );
  }
  
  redirectToEventDetail = (eventId) =>{
	  console.log('eventId>>', eventId);
  }

  singleTicketAllow = (string1, eventId) => {
	const url = 'http://localhost:3003/agency/event-detail/'+ eventId;
    return (
	<Link to ={{  pathname: `/agency/event-detail/${eventId}`,  state: {  tab: "1" }}} > {string1}</Link>
	);
  }
  clientData = () => {
    const sortedClientRequest = this.props.experienceClientRequest ? this.props.experienceClientRequest.sort((a, b) => new Date(b.createdon) - new Date(a.createdon)) : '';

    const clientReq = sortedClientRequest ? sortedClientRequest.map((req, index) => {
	  const eventId = req._evtech_eventid_value || '';
      const totalRequestRecieved = req.evtech_actualtotaltickets || 0;
      const adultRequestRecieved = req.evtech_actualchaperones || 0;
      const childRequestRecieved = req.evtech_actualchildren || 0;

      const totalRequestSend = req.evtech_totaltickets || 0;
      const adultRequestSend = req.evtech_numberofchaperones || 0;
      const childRequestSend = req.evtech_numberofchildren || 0;
      return {
        name: req.cr676_event_name,
		    eventId: eventId,
        requestedBy: req.evtech_chaperonename || '',
        totalRequestRecieved,
        adultRequestRecieved,
        childRequestRecieved,
        totalRequestSend,
        adultRequestSend,
        childRequestSend,
        received: req.evtech_confirmedtickets || ''
      }
    }) : [];

    clientReq.unshift({
      name: true,
      requestedBy: '',
      requestRecieved: true,
      requestSend: true,
      received: ''
    });
    return clientReq;
  }
    columnClient = [
      {
        dataField: 'name',
        text: 'Event Name',
        sort: true ,
        formatter: (col, row) => {
          if (row.name == true) {
            return '';
          }
          else {
            return this.singleTicketAllow(row.name, row.eventId);
          }
        }		
      },
      {
        dataField: 'requestedBy',
        text: 'Requested By',
        sort: true
      },
      {
        dataField: 'requestRecieved',
        text: 'Requests Received',
        formatter: (col, row) => {
          if (row.requestRecieved == true) {
            return this.tripleHeader({
              string1: "Adult",
              string2: "Children",
              string3: "Total"
            });
          }
          else {
            return this.totalTicketAllow(row.adultRequestRecieved, row.childRequestRecieved, row.totalRequestRecieved);
          }
        }
      },
      {
        dataField: 'requestSend',
        text: 'Request Sent',
        formatter: (col, row) => {
          if (row.requestSend == true) {
            return this.tripleHeader({
              string1: "Adult",
              string2: "Children",
              string3: "Total"
            });
          }
          else {
            return this.totalTicketAllow(row.adultRequestSend, row.childRequestSend, row.totalRequestSend);
          }
        },
      },
    ];

  render() {
   
    return (
      <div className="dashboard">
        <Container fluid={true}>
          <div className="dashboard-heading">
            <span className="section-icon">
              <Icon name="list" />
            </span>
            <h4>Client Request</h4>
          </div>
          <Row>
            <Col sm="12">
              <div className="event-table exp-client-request">
                <BootstrapTable keyField='id' data={this.clientData()} columns={this.columnClient} pagination={ paginationFactory() }/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    agencyCode: state.authReducer.user && state.authReducer.user.data.agency_code,
    experienceClientRequest: state.eventReducer.experienceClientRequest && state.eventReducer.experienceClientRequest.value,
  }
};

const mapDispatchTpProps = (dispatch) => ({
  getExperienceClientRequest: (agencyCode) => dispatch(getExperienceClientRequest(agencyCode))
});

export default withRouter(connect(mapStateToProps, mapDispatchTpProps)(clientRequest));