/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getFeaturedCategories } from "../../../../actions/EventActions";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css'; //Allows for server-side rendering.
import moment from "moment";
import DefaultButton from './../../../UI/atoms/DefaultButton';
import { Icon } from './../../../UI/atoms/Icon';
import randomImages from '../../../../assets/randomImages'
import { get } from 'lodash'

class FeaturedEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      allEvents: null,
      allCatgoriesChecked: {},
      allElements: {
        date: {
          operator: '',
          filter: 'evtech_startdate',
          eq: 'ge',
          id: moment().format("YYYY-MM-DD")
        }
      }
    }
  }

  componentDidMount() {
    // const evtech_city = this.props.user ? this.props.user.data && this.props.user.data.location : null;
    if (this.props.agency_account_detail?.owningbusinessunit?.businessunitid)
      this.props.getFeaturedCategories(this.props.agency_account_detail?.owningbusinessunit?.businessunitid);
  }

  featureEvents = () => {
    let values = [];
    const userPermission = this.props.user ? this.props.user.data && this.props.user.data.userPermission : null;
    if (Array.isArray(this.props.get_featured_events) && this.props.get_featured_events.length > 0) {
      this.props.get_featured_events.map((event, index) => {
        const eventImage = event.cr676_image ? `data:image/jpeg;base64,${event.cr676_image}` : randomImages[event.evtech_eventcategory] ? randomImages[event.evtech_eventcategory][index % randomImages[event.evtech_eventcategory].length] : randomImages.random[index % randomImages.random.length];
        values.push(
          <div key={index} >
            <div className="image-outer"><img src={eventImage} alt="eventName" /></div>
            <div className="event-des">
              <h5 title={event.evtech_name}>{event.evtech_name}</h5>
              <p><Icon name="calendar" />  {event.evtech_startdate ? moment(event.evtech_startdate).format("MMM DD, YYYY") : ''} {event.evtech_enddate ? "- " + moment(event.evtech_enddate).format("MMM DD, YYYY") : ''}</p>
              <p><Icon name="clock-o" /> {event.evtech_startdate ? moment.utc(event.evtech_startdate).format("hh:mm A") : ''} {event.evtech_enddate ? "- " + moment.utc(event.evtech_enddate).format("hh:mm A") : ''}</p>
              <p><Icon name="map-marker" /> {event.evtech_venueid ? event.evtech_venueid.evtech_name : ''}</p>

              {/*  <p>{event.evtech_description}</p> */}
            </div>
            {(userPermission) ?
              <Link to={{ pathname: `/agency/event-detail/${event.evtech_eventid}`, state: { eventImage } }} key={index}>
                <DefaultButton color="link" name="Read More" />
              </Link>
              :
              <Link to={{ pathname: `/agency/event-detail/${event.evtech_eventid}`, state: { eventImage } }} key={index}>
                <DefaultButton color="link" name="Read More" onClick={() => { this.props.generateTicketRequestFn(event.evtech_eventid, event.evtech_name) }} />
              </Link>
              // <DefaultButton color="link" name="Submit Request" onClick={() => { this.props.generateTicketRequestFn(event.evtech_eventid, event.evtech_name) }} />
            }
          </div >
        )
      })
      return (
        <>
          <h3>Featured Events</h3>
          <OwlCarousel options={{
            items: 3,
            nav: true,
            margin: 20,
            loop: true,
            responsiveClass: true,
            responsive: {
              678: {
                items: 1, // from this breakpoint 678 to 959
                center: true // only within 678 and next - 959
              },

              960: {
                items: 1, // from this breakpoint 960 to 1199
                margin: 20, // and so on...
                center: false

              },

              1200: {
                items: 2,
                loop: false,
                margin: 30,
              },
              1440: {
                items: 3,
                loop: false,
                margin: 30,
              }
            }
          }}  >
            {values}
          </OwlCarousel>
        </>
      );
    }
  }


  render() {

    let { date } = this.state;
    return (
      <>
        {this.featureEvents()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dynamic: state.authReducer.dynamic_user_detail,
    user: state.authReducer.user,
    get_featured_events: state.eventReducer.get_featured_events && state.eventReducer.get_featured_events.value,
    agency_account_detail: state?.authReducer?.agency_account_detail?.value?.[0]
  }

}


const mapDispatch = (dispatch) => ({
  getFeaturedCategories: (businessUnitId) => dispatch(getFeaturedCategories(businessUnitId)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(FeaturedEvents));
