import React, { Component } from 'react';
import Routes from "./routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

class App extends Component {
  render() {
      return (
          <main>
              <Routes />
              <ToastContainer />
          </main>
    );
  }
}

export default (App);
