import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';




class SearchInput extends Component {
    constructor() {
        super();
        this.state = {
            searchValue: ''
        };
      }


    static propTypes = {
      getSerach: PropTypes.func,
    };
   	async componentWillReceiveProps (props) {
		const {resetFilter} =  props;
		if (resetFilter) {
			await this.setState({searchValue: ''});
		}
	}
    render() {
      const { placeholder, getSerach } = this.props;
      return (
        <div>
           <DebounceInput
                placeholder={placeholder}
				value={this.state.searchValue}
                className='search-box form-control'
                forceNotifyByEnter={false}
                debounceTimeout={500}
                onChange={async (event) =>{ await this.setState({searchValue: event.target.value}, () => {
                  getSerach(event.target.value);
                 })}} /> 
          
        </div>
      );
    }


}

SearchInput.defaultProps = {
    placeholder: 'Search here..',
  };

export default SearchInput;
