import { SHOW_LOADER, HIDE_LOADER } from "../actions/UiActions";

const initialState = {
  isShowingLoader: false
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, isShowingLoader: true };
    case HIDE_LOADER:
      return { ...state, isShowingLoader: false };
    default:
      return state;
  }
}
