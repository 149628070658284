import React from "react";

export const ActionButtons = ({
  status,
  isModifying,
  modifyStatus,
  ticketRequestId,
  onModifyRow,
  onSaveChanges,
  onAccept,
  onReject
}) => {
  const isAcceptDisabled = modifyStatus ? false : status === 'Accepted' || status === 'Processed';
  const isRejectDisabled = modifyStatus ? false : status === 'Rejected' || status === 'Processed';
  const isModifyDisabled = status === 'Processed';

  return (
    
    <div className="action-buttons">
      {isModifying ? (
        <button className="btn btn-primary"
          onClick={() =>
            onSaveChanges({
              ticketRequestId
            })
          }
        >
          Save Changes
        </button>
      ) : (
          <>
            <button disabled={isAcceptDisabled} className="btn btn-primary" onClick={() => onAccept({ ticketRequestId })}>Accept</button>
            <button disabled={isRejectDisabled} className="btn btn-danger" onClick={() => onReject({ ticketRequestId })}>Reject</button>
            <button disabled={isModifyDisabled} className="btn btn-secondary" onClick={() => onModifyRow({ ticketRequestId })}>Modify</button>
          </>
        )}
    </div>
  );
}

export const TicketAllocationActionButtons = ({
  isModifying,
  status,
  remainingTickets,
  ticketRequestId,
  onModifyRow,
  onSaveAllocationRequest,
  onAllocate,
  unAllocate,
  onModify,
  totalTicketsTbs,
  totalAdultTicketsTbs,
  totalChildrenTicketsTbs
}) => {
  const isAllocatedDisabled = ((status === 'Allocated') || (remainingTickets < totalTicketsTbs)) ? true : false;
  console.log('isAllocatedDisabled', isAllocatedDisabled)
  const showUnAllocated = (status === 'Allocated') ? true : false;
  return (
    <div className="action-buttons">

      <>
        {showUnAllocated ? (<button className="btn btn-danger" onClick={() => unAllocate({ ticketRequestId })}>Unallocate</button>) : (<button disabled={isAllocatedDisabled} className="btn btn-primary" onClick={() => onAllocate({ ticketRequestId })}>Allocate</button>)}
        {isModifying ? (<button className="btn btn-primary" onClick={() => onSaveAllocationRequest({ ticketRequestId })}>{isModifying}Save Changes</button>) :
          (<>{!showUnAllocated ? <button className="btn btn-secondary" onClick={() => onModify(ticketRequestId)}>Modify</button> : ''}</>)}
      </>
    </div>
  );
}
