
import React from 'react';
import { FormGroup, Input, Label, Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from 'reactstrap';
import { DefaultInput, DefaultButton } from '../../UI/atoms/index';
import { checkValid, validate } from "../../../utils/validations";
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { validateLocation } from '../../../utils/validations';
import { getLocation } from '../../../actions/AuthActions';
import editIcon from "./../../../assets/images/edit-icon.png";
import { systemUserId } from '../../../actions/AuthActions';
import { last } from 'lodash';
class SignUpStepOne extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  componentDidMount(nextProps) {
    this.props.fetchLocationFn();
    if (!this.props.formData.cr676_location) {
      this.setState({ modal: true })
    }

  }

  submitLocation = () => {
    if (this.props.formData.cr676_location) {
      //debugger
      this.props.getSystemUserId(this.props.formData.cr676_location, true);
      this.setState({ modal: !this.state.modal })
    }
  };

  userLogin = () => {
    this.props.history.push('/');
  }

  render() {
    const { step1, checkValidation, checkValidationLocation, isPrimaryContact, isReceiveEventEmail, checkReceiveChange, checkChange, formData: { name, cr676_location, emailaddress1, extension, address1_line1, address1_city, address1_postalcode, telephone1,
      evtech_password, cr676_signingauthority, middlename, lastname, bsi_agencyclassification, cr676_sa_phonenumber, confirm_password, cr676_cellnumber, cr676_agency_code }, validationStep1: { emailV, passwordV, password_confirmationV, phone_numberV, cell_numberV, telephone1V, agencyV, agency_codeV,
        nameV, address1_line1V, address1_cityV, address1_postalcodeV, cr676_signingauthorityV, lastnameV }, validationLocation: { locationV }, onChangeEvent, agency_classifications } = this.props;
    return (

      <React.Fragment>
        <Modal isOpen={this.state.modal} className="event-popup modal-dialog-centered" id="select-location">
          <ModalHeader >Agency Registration {cr676_location} {checkValidationLocation}</ModalHeader>
          <ModalBody>
            <p>Please make sure you have had a conversation with someone at the Kids Up Front in your region before completing this application.</p>
            <FormGroup>
              <Row>
                <Col md="6" className="mt-4">
                  <select className="form-control" validation={locationV} name="cr676_location" value={cr676_location} onChange={(event) => onChangeEvent(event.target.name, event.target.value)} >
                    <option>Select Location </option>
                    {this.props.location && this.props.location.map((value, index) => {
                      return <option value={value.name} >{value.name}</option>
                    })}

                  </select>
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={checkValidationLocation} onClick={this.submitLocation}>Submit</Button>{' '}
          </ModalFooter>
        </Modal>


        <FormGroup>
          {(this.props.formData.cr676_location) &&
            <>
              <span>Location: {this.props.formData.cr676_location}</span>
              <img alt="Edit Location" className="edit-icon-img" src={editIcon} onClick={() => { this.setState({ modal: !this.state.modal }) }} />
            </>
          }
          <h3>Agency Details</h3>
          <Row>
            <Col md="4">
              <DefaultInput
                name="name"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={name}
                type="text"
                placeholder="Agency Name *"
                validation={nameV}
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="address1_line1"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={address1_line1}
                type="text"
                placeholder="Agency Address *"
                validation={address1_line1V}
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="address1_city"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={address1_city}
                type="text"
                placeholder="City *"
                validation={address1_cityV}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col md="4">
              <DefaultInput
                name="address1_postalcode"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={address1_postalcode}
                type="text"
                placeholder="Postal Code *"
                validation={address1_postalcodeV}
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="telephone1"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={telephone1}
                validation={telephone1V}
                type="text"
                placeholder="Phone Number *"
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="extension"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={extension}
                type="text"
                placeholder="Extension"
              />
            </Col>
          </Row>
        </FormGroup>


        <FormGroup>
          <h3>Executive Director</h3>
          <Row>
            <Col className="hlt-checkbox">

              <CustomInput
                id="exampleCheckbox2"
                onChange={
                  () =>
                    checkChange()

                } type="checkbox" checked={isPrimaryContact} name="isPrimaryContact" label="Is this the Primary Contact?" />{' '}
            </Col>

          </Row>
          <Row>
            <Col className="hlt-checkbox">

              <CustomInput
                id="exampleCheckbox3"
                onChange={
                  () =>
                    checkReceiveChange()

                } type="checkbox" checked={isReceiveEventEmail} name="isReceiveEventEmail" label="Receive New Event Notifications?" />{' '}
            </Col>

          </Row>
          <Row>

            <Col md="4">
              <DefaultInput
                name="cr676_signingauthority"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={cr676_signingauthority}
                type="text"
                placeholder="First Name *"
                validation={cr676_signingauthorityV}
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="middlename"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={middlename}
                type="text"
                placeholder="Middle Name"
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="lastname"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={lastname}
                type="text"
                placeholder="Last Name *"
                validation={lastnameV}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col md="4">
              <DefaultInput
                name="cr676_sa_phonenumber"
                value={cr676_sa_phonenumber}
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                type="text"
                validation={phone_numberV}
                placeholder="Phone Number *"
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="emailaddress1"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={emailaddress1}
                validation={emailV}
                placeholder="Email Address *"
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="cr676_cellnumber"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={cr676_cellnumber}
                validation={cell_numberV}
                placeholder="Cell Number"
              />
            </Col>

          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md="4">
              <DefaultInput
                name="evtech_password"
                type="password"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={evtech_password}
                validation={passwordV}
                placeholder="Password *"
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="confirm_password"
                type="password"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={confirm_password}
                validation={password_confirmationV}
                placeholder="Confirm Password *"
              />
            </Col>

            <Col md="4">
              <DefaultInput
                name="cr676_agency_code"
                type="hidden"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={cr676_agency_code}
                validation={agency_codeV}
                placeholder="Agency Code *"
              />
            </Col>
          </Row>
        </FormGroup>


        <FormGroup>
          <h3>Organization incorporated as <span style={{ color: "red" }}>*</span></h3>
          <Row>

            <Col md="4">              
              <select className="form-control" validation={agencyV} name="bsi_agencyclassification" onChange={(event) => onChangeEvent(event.target.name, event.target.value)} >
                <option>Select</option>
                {Array.isArray(agency_classifications) &&
                  agency_classifications.map((value, index) => <option key={index} value={value.id} selected={bsi_agencyclassification == value.id}>{value.name}</option>)
                }
                {/* <option value='861560000' selected={bsi_agencyclassification == 861560000}>Charity</option>
                <option value='861560001' selected={bsi_agencyclassification == 861560001}>Society/NFP</option>
                <option value='861560002' selected={bsi_agencyclassification == 861560002}>Government Program</option>
                <option value='861560003' selected={bsi_agencyclassification == 861560003}>School</option>
                <option value='861560004' selected={bsi_agencyclassification == 861560004}>Other</option> */}
              </select>
            </Col>
            {/*<Col md="4">*/}
            {/*  <DefaultInput*/}
            {/*    name="Please Specify Others"*/}
            {/*    type="text"*/}
            {/*    placeholder="Please Specify Others"*/}
            {/*  />*/}
            {/*</Col>*/}

          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col sm="12" className="d-flex justify-content-end mt-3">
              <DefaultButton name="BACK TO LOGIN" color="secondary" onClick={this.userLogin} size="sm" />
              <DefaultButton
                name="CONTINUE"
                disabled={checkValidation}
                color="primary"
                onClick={() => step1()}
                size="sm"
              />

            </Col>
          </Row>
        </FormGroup>

      </React.Fragment>

    )

  }

}

function mapStateToProps(state) {
  return {
    location: state.authReducer.location,
    agency_classifications: state.authReducer.agency_classifications

  };
}
const mapDispatch = dispatch => ({
  fetchLocationFn: location => dispatch(getLocation()),
  getSystemUserId: (location, signup) => dispatch(systemUserId(location, signup))
});
export default withRouter(connect(mapStateToProps, mapDispatch)(SignUpStepOne));

