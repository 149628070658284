import axios from "axios";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { history } from '../store';
export const SET_INITIAL_AUTH_NEW = 'SET_INITIAL_AUTH_NEW';

export default {
  setupInterceptors: (store) => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        if (response.data.code >= 400 && response.data.code <= 500) { // in case response status is 200 and response code is >= 400
          toast.error(response.data.message);
        }
        let res = {};
        res.headers = response.headers;
        res.data = response.data;
        res.status = response.status;
        return res;
      },
      function (error) { // in case request error code is >= 400
        //catches if the session ended!
        if (error && error.response && error.response.status) {
          localStorage.setItem('loader', false);
          let error_message = error.response.data.message ? error.response.data.message : error.message;
          // You are not authorized 
          // store.dispatch({ type: REFRESH_TOKEN });
          if (error.response.status === 401) {
            history.push('/');
            let payload = true;
            Promise.all([store.dispatch({ type: SET_INITIAL_AUTH_NEW, payload })]).then(() => '');
          } else {
            toast.error(error_message);
          }

          return Promise.reject(error.response.data);
        }
      }
    );
  }
};
