import React from 'react';
import { Row, Col, FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { DefaultInput, DefaultButton } from '../../UI/atoms/index';
import { toast } from 'react-toastify';
import TermsAndConditions from './TermsAndCondition'

const AlternativeTicketsForm = ({
  lastname,
  middlename,
  cr676_alternate_ticket_contact_name,
  cr676_alternate_ticket_contact_position,
  cr676_alternate_ticket_contact_phone_number,
  cr676_alternate_ticket_contact_cell_number,
  cr676_alternate_ticket_contact_email,
  cr676_alternate_ticket_contact_password,
  cr676_alternate_ticket_contact_confirm_password,
  validations,
  key,
  onChange,
  index,
  deleteAlternativeTicketsForm,
  disableddelete
}) => (
  <React.Fragment key={key}>
    <div className="ex-cord">
      <FormGroup>
        <h3>Alternate Experience Coordinator {index + 1}</h3>
        <Row>
          <Col md="4">
            <DefaultInput
              name="cr676_alternate_ticket_contact_name"
              value={cr676_alternate_ticket_contact_name}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              type="text"
              placeholder="First Name"
            />
          </Col>
          <Col md="4">
            <DefaultInput
              name="middlename"
              value={middlename}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              type="text"
              placeholder="Middle Name"

            />
          </Col>
          <Col md="4">
            <DefaultInput
              name="lastname"
              value={lastname}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              type="text"
              placeholder="Last Name"

            />
          </Col>

        </Row>
      </FormGroup>

      <FormGroup>
        <Row>
          <Col md="4">
            <DefaultInput
              name="cr676_alternate_ticket_contact_position"
              value={cr676_alternate_ticket_contact_position}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              type="text"
              placeholder="Position"
            />
          </Col>
          <Col md="4">
            <DefaultInput
              name="cr676_alternate_ticket_contact_phone_number"
              value={cr676_alternate_ticket_contact_phone_number}
              // validation={validations.AltcontactPhoneNumberV}
              validation={validations && validations[validations.findIndex(item => item.index === index)] && validations[validations.findIndex(item => item.index === index)].AltcontactPhoneNumberV}

              onChange={(event) => onChange(event.target.name, event.target.value)}
              type="text"
              placeholder="Office Number"
            />
          </Col>
          <Col md="4">
            <DefaultInput
              name="cr676_alternate_ticket_contact_cell_number"
              value={cr676_alternate_ticket_contact_cell_number}
              // validation={validations.AltTicketContactCellNumberV}
              validation={validations && validations[validations.findIndex(item => item.index === index)] && validations[validations.findIndex(item => item.index === index)].AltTicketContactCellNumberV}

              onChange={(event) => onChange(event.target.name, event.target.value)}
              type="text"
              placeholder="Mobile Number"
            />
          </Col>

        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col md="4">
            <DefaultInput
              name="cr676_alternate_ticket_contact_email"
              value={cr676_alternate_ticket_contact_email}
              validation={validations && validations[validations.findIndex(item => item.index === index)] && validations[validations.findIndex(item => item.index === index)].emailV}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              type="email"
              placeholder="Email Address"
            />
          </Col>
          {/* <Col md="4">
              <DefaultInput
                name="cr676_alternate_ticket_contact_password"
                value={cr676_alternate_ticket_contact_password}
                validation={validations && validations[validations.findIndex(item => item.index === index)] && validations[validations.findIndex(item => item.index === index)].passwordV}
                onChange={(event) => onChange(event.target.name, event.target.value)}
                type="password"
                placeholder="Password"
              />
            </Col> */}
          {/* <FormGroup>
          <Row>
          <Col md="4">
              <DefaultInput
                name="cr676_alternate_ticket_contact_confirm_password"
                value={cr676_alternate_ticket_contact_confirm_password}
                validation={validations && validations[validations.findIndex(item => item.index === index)] && validations[validations.findIndex(item => item.index === index)].confirmPasswordV}
                onChange={(event) => onChange(event.target.name, event.target.value)}
                type="password"
                placeholder="Conform Password*"
              />
            </Col>
          </Row>
        </FormGroup> */}
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col lg="12">
            <Button
              color="info"
              onClick={() => deleteAlternativeTicketsForm()}
              disabled={disableddelete}
            //   this.props.formData.cr676_alternate_tickets_collection1.length === 0
            // }
            >
              Delete
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </div>

  </React.Fragment>
);

export default class SignUpStepThree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  termConditionSubmit = () => {
    this.setState({ modal: !this.state.modal })
  };
  printTermCondition = () => {
    var content = document.getElementById("term-condition");
    let pri
    const iframe = document.createElement('iframe')
    iframe.setAttribute('title', 'Term And Condition')
    iframe.setAttribute('id', 'term-iframe')
    iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
    document.body.appendChild(iframe)
    pri = iframe.contentWindow;
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
  };

  addAlternativeTicketsForm = () => {
    const emptyAltTicket = {
      middlename: '',
      lastname: '',
      cr676_alternate_ticket_contact_name: '',
      cr676_alternate_ticket_contact_position: '',
      cr676_alternate_ticket_contact_phone_number: '',
      cr676_alternate_ticket_contact_cell_number: '',
      cr676_alternate_ticket_contact_email: '',
      cr676_alternate_ticket_contact_password: '',
      cr676_alternate_ticket_contact_confirm_password: '',

    };

    const altTickets = this.props.formData.cr676_alternate_tickets_collection1 || [];
    if (altTickets.length >= 50) {
      toast.error('Cannot add more than 50 ticket alternative tickets');
      return;
    }

    this.props.onChangeEvent(
      'cr676_alternate_tickets_collection1',
      altTickets.concat([emptyAltTicket]),
    );
  };

  deleteAlternativeTicketsForm = (index) => {
    let altTickets = this.props.formData.cr676_alternate_tickets_collection1 || [];
    if (altTickets.length === 1) {
      toast.error('Cannot delete alternative tickets');
      return;
    }
    altTickets.splice(index, 1)
    this.props.onChangeEvent(
      'cr676_alternate_tickets_collection1',
      altTickets,
    )

  }
  handleAlternativeTicketChange = (index) => (name, value) => {
    const altTickets = this.props.formData.cr676_alternate_tickets_collection1 || [];

    const newAltTickets = altTickets.map((ticket, tIndex) => {
      if (index !== tIndex) {
        return ticket;
      }

      return {
        ...ticket,
        [name]: value,
      };
    });
    this.props.onChangeEvent('cr676_alternate_tickets_collection1', newAltTickets, index, name);
  };

  render() {
    const {
      finalStep,
      checkValidationStep3,
      isPrimaryContact,
      formData: {
        primary_lastname,
        primary_middlename,
        cr676_key_ticket_contact_name,
        cr676_key_ticket_contact_position,
        cr676_key_ticket_contact_office_number,
        cr676_key_ticket_contact_cell_number,
        cr676_key_ticket_contact__email,
        cr676_key_ticket_contact__password,
        cr676_key_ticket_contact__confirm_password,
        cr676_alternate_tickets_collection1,
      },
      validationStep3: {
        ticketContactCellNumberV,
        ticketContactCellNumberV2,
        ticketContactEmailV,
        ticketContactpasswordconfirmationV,
        ticketContactpasswordV,
        AltcontactPhoneNumberV,
        AltTicketContactCellNumberV,
        AltTicketContactEmailV,
        AllternativeValidation
      },
      onChangeEvent,
    } = this.props;

    const altTicketFormValidations = {
      AltcontactPhoneNumberV,
      AltTicketContactCellNumberV,
      AltTicketContactEmailV,
    };

    return (
      <React.Fragment>
        <Modal isOpen={this.state.modal} className="event-popup modal-dialog-centered">
          <ModalHeader >Terms and Conditions</ModalHeader>
          <ModalBody>
            <TermsAndConditions />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.printTermCondition}>Print</Button>{' '}
            <Button color="primary" onClick={() => {
              finalStep(this.state, false, true);
            }}>Agree</Button>{' '}
            <Button color="secondary" onClick={this.termConditionSubmit}>Cancel</Button>{' '}
          </ModalFooter>
        </Modal>
        <FormGroup>
          <h3>Primary Experience Coordinator</h3>
          <Row>
            <Col md="4">
              <DefaultInput
                name="cr676_key_ticket_contact_name"
                value={cr676_key_ticket_contact_name}
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                placeholder="First Name"
                disabled={isPrimaryContact}
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="primary_middlename"
                value={primary_middlename}
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                placeholder="Middle Name"
                disabled={isPrimaryContact}
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="primary_lastname"
                value={primary_lastname}
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                placeholder="Last Name"
                disabled={isPrimaryContact}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col md="4">
              <DefaultInput
                name="cr676_key_ticket_contact_position"
                value={cr676_key_ticket_contact_position}
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                placeholder="Position"
                disabled={isPrimaryContact}
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="cr676_key_ticket_contact_office_number"
                value={cr676_key_ticket_contact_office_number}
                validation={ticketContactCellNumberV2}
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                type="text"
                placeholder="Office Number"
                disabled={isPrimaryContact}
              />
            </Col>
            <Col md="4">
              <DefaultInput
                name="cr676_key_ticket_contact_cell_number"
                value={cr676_key_ticket_contact_cell_number}
                validation={ticketContactCellNumberV}
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                type="text"
                placeholder="Mobile Number"
                disabled={isPrimaryContact}
              />
            </Col>

            {/* <Col md="4">
            <DefaultInput
                name="cr676_key_ticket_contact__password"
                type="password"
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                value={cr676_key_ticket_contact__password}
                validation={ticketContactpasswordV}
                placeholder="Password *"
            />
          </Col>
        */}
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md="4">
              <DefaultInput
                name="cr676_key_ticket_contact__email"
                value={cr676_key_ticket_contact__email}
                validation={ticketContactEmailV}
                onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
                type="email"
                placeholder="Email Address"
                disabled={isPrimaryContact}
              />
            </Col>
          </Row>
        </FormGroup>
        {/* <FormGroup>
          <Row>
          <Col md="4">
            <DefaultInput
              name="cr676_key_ticket_contact__confirm_password"
              type="password"
              onChange={(event) => onChangeEvent(event.target.name, event.target.value)}
              value={cr676_key_ticket_contact__confirm_password}
              validation={ticketContactpasswordconfirmationV}
              placeholder="Confirm Password *"
            />
          </Col>
       
          </Row>
        </FormGroup> */}

        <div>
          { }
          {cr676_alternate_tickets_collection1 && cr676_alternate_tickets_collection1.map((vals, index) => (
            <AlternativeTicketsForm
              {...vals}
              validations={AllternativeValidation}
              onChange={this.handleAlternativeTicketChange(index)}
              deleteAlternativeTicketsForm={() => this.deleteAlternativeTicketsForm(index)}
              disableddelete={
                checkValidationStep3 &&
                this.props.formData.cr676_alternate_tickets_collection1.length === 0
              }
              index={index}
            />
          ))}
        </div>

        <Button
          color="info"
          onClick={this.addAlternativeTicketsForm}
          disabled={
            checkValidationStep3 &&
            this.props.formData.cr676_alternate_tickets_collection1.length >= 3
          }>
          Add More
        </Button>


        <FormGroup>
          <Row>
            <Col sm="12" className="d-flex justify-content-end mt-3">
              <DefaultButton
                name="BACK"
                color="secondary"
                onClick={() => {
                  finalStep(this.state, true, false);
                }}
                size="sm"
              />
              <DefaultButton
                name="CONTINUE"
                color="primary"
                disabled={checkValidationStep3}
                onClick={() => {
                  this.termConditionSubmit()
                  //finalStep(this.state, false, true);
                }}
                size="sm"
              />
            </Col>
          </Row>
        </FormGroup>

      </React.Fragment>
    );
  }
}
